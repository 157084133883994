import { pointsToPixels } from "@/utils/utils"

export const BASE_WIDTH = 595.32 // Por defecto A4 (pt)
export const BASE_HEIGHT = 842 // Por defecto A4 (pt)

export const BASE_WIDTH_MOBILE = 330
export const BASE_HEIGHT_MOBILE = 467

export const WIDTH_ZOOM_STEP = 193
export const HEIGHT_ZOOM_STEP = 273.333

export const MIN_WIDTH = 192
export const MIN_HEIGHT = 248

export const MAX_WIDTH = 1549
export const MAX_HEIGHT = 2191

export const PDF_EMBED_WIDTH_HEIGHT_FACTOR = 0.707
export const DESKTOP_WIDTH_ZOOM_LIMIT = 407

export const DESKTOP_ZOOM_SELECT_VALUES = [75, 100, 125, 150]

export const ZOOM_PERCENTAGE_TO_SIZE = {
  "100": {
    width: pointsToPixels(BASE_WIDTH),
    height: pointsToPixels(BASE_HEIGHT),
  },

  "75": {
    width: pointsToPixels(BASE_WIDTH) - WIDTH_ZOOM_STEP,
    height: pointsToPixels(BASE_HEIGHT) - HEIGHT_ZOOM_STEP,
  },

  "125": {
    width: pointsToPixels(BASE_WIDTH) + WIDTH_ZOOM_STEP,
    height: pointsToPixels(BASE_HEIGHT) + HEIGHT_ZOOM_STEP,
  },

  "150": {
    width: pointsToPixels(BASE_WIDTH) + (WIDTH_ZOOM_STEP * 2),
    height: pointsToPixels(BASE_HEIGHT) + (HEIGHT_ZOOM_STEP * 2),
  },

}

export default {
  BASE_WIDTH,
  BASE_HEIGHT,
  WIDTH_ZOOM_STEP,
  HEIGHT_ZOOM_STEP,
  MIN_WIDTH,
  MIN_HEIGHT,
  MAX_WIDTH,
  MAX_HEIGHT,
  DESKTOP_WIDTH_ZOOM_LIMIT,
  ZOOM_PERCENTAGE_TO_SIZE,
  DESKTOP_ZOOM_SELECT_VALUES,
  BASE_WIDTH_MOBILE,
  BASE_HEIGHT_MOBILE,
  PDF_EMBED_WIDTH_HEIGHT_FACTOR
}