<template>
  <v-dialog
    v-model="dialogOpen"
    :max-width="maxWidth"
    :persistent="persistent"
    @click:outside="$emit('click:outside')"
  >
    <slot />
  </v-dialog>
</template>

<script>
  export default {
    name: "GenericDialog",
    props: {
      persistent: Boolean,
      maxWidth: {
        type: [Number, String],
        default: () => 500
      }
    },

    data() {
      return {
        dialogOpen: true,
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>