<template>
  <div class="fr-invalid-route">
    <v-toolbar class="fr-invalid-route-header px-0" elevation="0">
      <v-row justify="center" dense class="ma-0">
        <v-col cols="4" class="text-center" align-self="center">
          <img src="https://r.mailcomms.io/logo-certysign-RGB-1.png" class="fr-invalid-route-header-logo" alt="CertySign logo" />
        </v-col>
      </v-row>
    </v-toolbar>
    <div class="fr-invalid-route-content">
      <v-row justify="center" class="pb-4">
        <img src="https://r.mailcomms.io/logo-certysign-RGB-1.png" width="200" alt="CertySign logo"/>
      </v-row>
      <v-row justify="center">
        <v-card class="fr-invalid-route-content-card" flat>
          <v-row justify="center">
            <span class="title text-center fr-invalid-route-content-card__text">Ruta no encontrada:</span>
          </v-row>
          <v-row justify="center" class="py-2">
            <span class="text-center fr-invalid-route-content-card__text">Por favor, póngase en contacto con la entidad que le ha facilitado el enlace.</span>
          </v-row>

          <v-divider :class="($vuetify.breakpoint.smAndDown) ? 'fr-invalid-route-content-card__divider-mobile' : 'fr-invalid-route-content-card__divider'"/>

          <v-row justify="center" class="py-2">
            <span class="title text-center fr-invalid-route-content-card__text">Path not found:</span>
          </v-row>

          <v-row justify="center">
            <span class="text-center fr-invalid-route-content-card__text">Please contact the organisation that provided you with the message.</span>
          </v-row>
        </v-card>
      </v-row>
    </div>
  </div>
</template>

<script>
import { changePageTitle } from "@/utils/utils"

  export default {
    name: "InvalidRoute",

    created() {
      changePageTitle("Ruta no encontrada | Certysign")
    }
  }
</script>

<style lang="scss">
  .fr-invalid-route {
    height: 100%;
    background-color: white !important;

    &-header {
      margin-top: auto;
      margin-bottom: auto;
      padding: 0px 36px;
      height: 60px !important;
      min-height: 60px !important;
      max-height: 60px !important;
      border-bottom: solid 1px #E2E2E2 !important;

      .v-toolbar__content{
        height: 60px !important;
        min-height: 60px !important;
        max-height: 60px !important;
      }

      &-logo {
        height: 38px;
        width: auto;
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    &-content {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 100% !important;
      // padding: 24px;

      &-card {
        background-color: red;
        width: 50% !important;

        &__text {
          color: #4C4C4C !important;
        }

        &__divider {
          border-color: #579689 !important;
          width: 60% !important;

          &-mobile {
            width: 100% !important;

          }
        }
      }
    }
  }


</style>