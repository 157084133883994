<template>
  <GenericDialog max-width="500" persistent>
    <v-card>
      <v-card-subtitle class="pb-2">
        <v-row dense justify="center" class="pb-0 pt-3">
          <v-col cols="12" class="text-center">
            <v-icon id="autofirma-info-dialog-icon" size="65" color="primary">lock_clock</v-icon>
          </v-col>

          <v-col cols="12" class="text-center">
            <h1
              id="autofirma-info-dialog-title"
              class="fr-identification_model-1__title font-style-title"
              v-html="mapping.signatureAutofirmaTitle"
            >
            </h1>
            <v-divider/>
          </v-col>


          <v-col cols="12" v-if="loading" class="text-center pt-2 pb-4">
            <v-progress-circular indeterminate color="primary" size="48" />
          </v-col>

          <v-col v-else cols="12" class="text-center">
            <p
              v-if="error === 'timeout'"
              id="autofirma-info-dialog-text"
              class="fr-identification_model-1__introduction-text font-style-introduction"
              v-html="mapping.signatureAutofirmaTimeout"
            >
            </p>
            <p
              v-else-if="error === 'canceled_by_user'"
              id="autofirma-info-dialog-text"
              class="fr-identification_model-1__introduction-text font-style-introduction"
              v-html="mapping.signatureAutofirmaCanceledByUser"
            >
            </p>
            <p
              v-else-if="error === 'authentication_error'"
              id="autofirma-info-dialog-text"
              class="fr-identification_model-1__introduction-text font-style-introduction"
              v-html="mapping.signatureAutofirmaErrorCertificate"
            >
            </p>
            <p v-else
              id="autofirma-info-dialog-text"
              class="fr-identification_model-1__introduction-text font-style-introduction"
              v-html="mapping.signatureAutofirmaInfo"
            >
            </p>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-actions v-if="!loading">
        <v-row justify="space-around" dense class="ma-0">
          <div class="pb-5">
            <v-btn
              id="autofirma-info-dialog-submit"
              block
              @click="error ? $emit('back') : $emit('confirmSignatures')"
              color="primary"
            >
              {{ error ? mapping.backToDocument : mapping.submit }}
            </v-btn>
          </div>

<!--          <v-col v-if="!error" cols="12" class="text-center">-->
<!--            <a @click="$emit('back')">{{ mapping.backToDocument }}</a>-->
<!--          </v-col>-->
        </v-row>
      </v-card-actions>
    </v-card>
  </GenericDialog>
</template>

<script>
  import GenericDialog from "@/components/general/GenericDialog"
  export default {
    name: "AutofirmaInformationDialog",
    components: { GenericDialog },
    props: {
      mapping: Object,
      error: [Boolean, String],
      loading: Boolean
    }
  }
</script>
