<template>
  <component
    id="fr-single-click-signature-bar-model"
    :is="createdComponent"
    :viewVariables="{ mapping, width: barWidth, buttonEnabled, activateOnScroll, allowRejection }"
    @submitSignatures="$emit('submitSignatures')"
    @showRejectDialog="$emit('showRejectDialog')"
  />

</template>

<script>
import { htmlVariableReplace, createModelComponent, createComponentData } from "@/utils/utils"
import { getModelResources } from "@/utils/modelUtils"

export default {
  name: "SingleClickSignatureBarWrapper",
  props: {
    typology: String,
    model: String,
    mapping: Object,
    width: Number,
    activateOnScroll: Boolean,
    allowRejection: Boolean,
    rendererVersion: String
  },

  data() {
    return {
      createdComponent: null,
      scrollCompleted: false
    }
  },

  computed: {
    barWidth() {
      return this.width < 400 ? window.innerWidth : this.width
    },

    footerHeight() {
      return document.querySelector("footer")?.offsetHeight || 0
    },

    buttonEnabled() {
      return this.activateOnScroll ? this.scrollCompleted : true
    }
  },

  async created() {
    const modelResources = await getModelResources(this.typology, this.model)
    this.html = htmlVariableReplace(modelResources.html, modelResources.modelObject.variables, this.mapping)
    this.modelObject = modelResources.modelObject
    const componentData = createComponentData(this.modelObject.requestVariables)

    this.createdComponent = createModelComponent(this.html, componentData)

    this.$emit("componentLoaded")

  },

  mounted() {
    if (this.rendererVersion === "paginationModel") {
      this.$eventBus.on("documentScrolled", () => {
        this.scrollCompleted = true
      })
    } else {
      window.addEventListener('scroll', this.handleScroll)
    }
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll() {
      const scrollY = window.scrollY
      const windowHeight = window.innerHeight
      const documentHeight = document.documentElement.scrollHeight

      if (scrollY + this.footerHeight + windowHeight >= documentHeight) {
        window.removeEventListener('scroll', this.handleScroll)
        this.scrollCompleted = true
      }
    }


  }
}
</script>
