var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-fade-transition',[(!_vm.isIdentified)?_c(_vm.createdComponent,{ref:"otpcomponent",tag:"component",attrs:{"id":"fr-identification-model","viewVariables":{
      otpError: _vm.otpError,
      requestNewOtpError: _vm.requestNewOtpError,
      loading: _vm.loading,
      responseMessage: _vm.responseMessage,
      otpSent: _vm.otpSent,
      VERIDAS_URL: _vm.VERIDAS_URL,
      documentImageToken: _vm.documentImageToken,
      veridasTokenError: _vm.veridasTokenError,
      veridasError: _vm.veridasError,
      veridasStep: _vm.veridasStep
    }},on:{"submit":_vm.handleSubmit,"requestNewOtp":_vm.handleRequestNewOtp,"requestOtp":_vm.requestOtp,"requestNewVeridasId":_vm.handleRequestNewVeridasIdentification,"changeStep":_vm.changeStep}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }