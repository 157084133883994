<template>
  <v-menu bottom offset-y v-if="availableLanguages">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        text
        v-bind="attrs"
        v-on="on"
      >
        public
      </v-icon>
    </template>

    <v-list>
        <v-list-item
          v-for="lang in availableLanguages"
          :key="lang"
        >
          <v-list-item-title>
            <country-flag :country='iso3166Lang(lang)' size='small'/>
          </v-list-item-title>
        </v-list-item>
      </v-list>

  </v-menu>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import languages from "@/constants/languagesISO3166"
export default {
  name: 'LanguageSelector',

  props: {
    availableLanguages: Array
  },

  components: {
    CountryFlag
  },

  data () {
    return {
      selectedLanguage: "eng"
    }
  },

  created() {
    console.log("aa", this.iso3166Lang("esp"))
  },

  methods: {
    iso3166Lang(lang) {
      return languages[lang]
    }
  }
}
</script>
