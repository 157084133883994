<template>
  <div
    class="fr-document-reject_container"
  >
    <v-btn
      color="var(--v-primary-base-3)"
      small
      text

      elevation="0"
      class="fr-document-reject_container__button"
      @click="showRejectDialog"
    >
      {{ mapping.rejectionButtonText }}
    </v-btn>
  </div>
</template>

<script>

export default {
  name: 'SignatureRejectionLink',
  props: {
    mapping: Object,
    prevBtnDisabled: Boolean,
    nextBtnDisabled: Boolean,
    documentName: String,
    width: Number
  },
  methods:{
    showRejectDialog(){
      this.$emit('showRejectDialog')
    }
  }

}
</script>

<style lang="scss">
  .fr-document-reject_container {
    &__button {
      text-transform: none !important;
      font-weight: normal !important;
      color: var(--v-primary-base) !important;
      font-size: 14px !important;
      height: 40px !important;
      padding: 0 16px !important;
    }
  }
</style>
