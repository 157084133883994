<template>
  <GenericDialog
    max-width="500"
    @click:outside="$emit('back')"
  >
    <v-card>
      <v-card-subtitle class="pb-2">
        <v-row dense justify="center" class="pb-0 pt-3">
          <v-col cols="12" class="text-center">
            <v-icon id="document-model-2-sign-icon" size="65" color="primary">lock_clock</v-icon>
          </v-col>
          <v-col class="text-center">
            <h1 id="document-model-2-sign-title" class="fr-identification_model-1__title font-style-title">
              {{ mapping.signaturePopupTitle }}
            </h1>
            <v-divider/>
            <p id="document-model-2-sign-text" class="fr-identification_model-1__introduction-text font-style-introduction">
              {{ mapping.signaturePopupIntroductionText }}
            </p>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-actions>
        <v-row justify="space-around" dense class="ma-0">
          <div class="pb-5">
            <v-btn
              id="document-model-2-sign-submit"
              block
              color="primary"
              :loading="loading"
              @click="handleSubmit"
            >
              {{ mapping.submit }}
            </v-btn>
          </div>
          <v-col cols="12" class="text-center">
            <a @click="$emit('back')">
              {{ mapping.backToDocument }}
            </a>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </GenericDialog>
</template>

<script>
import GenericDialog from "@/components/general/GenericDialog"
  export default {
    name: "ConfirmSignaturesDialog",
    components: { GenericDialog },
    props: {
      mapping: Object
    },

    data() {
      return {
        loading: false
      }
    },

    methods: {
      handleSubmit() {
        this.loading = true
        this.$emit('confirmSignatures')
      }
    }
  }
</script>
