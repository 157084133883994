<template>
  <component
    :is="createdComponent"
    :viewVariables="{}"
    id="fr-waiting_other_interveners-model"
  />
</template>

<script>
import { htmlVariableReplace, createModelComponent } from "@/utils/utils"
import { getModelResources } from "@/utils/modelUtils"

export default {
  name: "WaitingOtherIntervenersWrapper",

  props: {
    dataObject: Object
  },

  data() {
    return {
      createdComponent: null,
    }
  },

  async created() {
    const modelResources = await getModelResources(this.dataObject.typology, this.dataObject.model)
    this.html = htmlVariableReplace(modelResources.html, modelResources.modelObject.variables, this.dataObject.mapping)
    this.modelObject = modelResources.modelObject
    const componentData = {}

    this.createdComponent = createModelComponent(this.html, componentData)

  }
}
</script>
