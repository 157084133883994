import modelServices from "@/services/modelServices"
import { loadCssResource } from "@/utils/utils"

export const getModelResources = async (modelType, modelName) => {
  let modelDefinition = await modelServices.fetchModelDefinition(modelType, modelName)
  const html = await modelServices.fetchModelHTML(modelType, modelName)
  loadCssResource(`${process.env.VUE_APP_RESOURCES_ENDPOINT}models/${modelType}/${modelName}/style.css`)

  modelDefinition = modelDefinition.data
  modelDefinition.variables = modelDefinition.variables.map(x => `%%${x}%%`)

  return { modelObject: modelDefinition, html: html.data }
}

export const otpCustomBehaviour = async (selector, component) => {
  const inputs = document.querySelectorAll(selector)

  const clearInput = (element) => {
    element.value = ""
    component.$data.returnData.formValue = null
    inputs[0].focus()
  }

  inputs.forEach(x => {        
    x.addEventListener("click", event => {
      event.preventDefault()
      event.stopPropagation()
      inputs.forEach(el => clearInput(el))
    })

    x.addEventListener("paste", event => {
      event.preventDefault()
      let code = event.clipboardData.getData('text')
      if (code) {
        code = code.trim()
        
        if (code.match(/^\d{6}$/)) {
          component.$data.returnData.formValue = code

          code = code.split("")
          for (let i = 0; i < code.length; i++) {
            inputs[i].value = code[i]
          }
        }
      }

    })
  })
}

export const focusNextSignature = () => {
  const pendingSignatures = document.querySelectorAll(".fr-signature-button-pending")

  if (pendingSignatures[0].classList.contains("fr-signature-button-focused")) {
    pendingSignatures[0].classList.remove("fr-signature-button-focused")

  }

  pendingSignatures[0].scrollIntoView({block: "center", behavior: "smooth"})
  pendingSignatures[0].classList.add("fr-signature-button-focused")

  
}