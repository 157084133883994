import Vue from "vue"
import vuetifyComponents from "@/utils/vuetifyComponents"

export const loadCssResource = url => {
  const newLink = document.createElement("link")
  newLink.rel = "stylesheet"
  newLink.type = "text/css"
  newLink.href = url

  document.getElementsByTagName("head")[0].appendChild(newLink)
  return document
}

export const htmlVariableReplace = (html, model, values) => {
  model.forEach(v => {
    html = html.replace(new RegExp(v, "g"), values[v.replace(/%%/g, "")])
  })

  return html
}

export const changeFavicon = favicon => {
  document.querySelector("link[rel~='icon']").href = favicon
}

export const changePageTitle = title => {
  document.title = title
  return document
}

export const createModelComponent = (template, componentData, mounted=()=>{}, methods={}) => {
  return Vue.component("component-model", {
    template,
    components: vuetifyComponents,
    props: {
      'viewVariables':{
        type: Object,
        default: null
      }
    },
    data() { return componentData },
    mounted,
    methods: {
      ...methods,
      handleClick(eventName, extra=null) {
        if (this.returnData) {
          this.$emit(eventName, { componentData: this.$data, extra })
        }
      },

      emitGlobal(eventName, value=null) {
        this.$eventBus.emit(eventName, value)
      }
    }
  })
}

export const createComponentData = (variables, objectName="returnData") => {
  return {
    [objectName]: Object.fromEntries(variables.map(x => {
      if (x.type == "array") {
        return [x.name, []]
      }

      return [x.name, null]
    }))
  }
}

export const generateFileDownload = (fileContent, fileName, fileType) => {
  const downloadLink = document.createElement('a')
  document.body.appendChild(downloadLink);
  fileType = "application/octet"
  downloadLink.href = `data:${fileType};base64,${fileContent}`
  downloadLink.target = '_self'
  downloadLink.download = fileName
  downloadLink.click()
}

export const globalLoading = (eventBus, time=1000) => {
  eventBus.emit("loading", true)
  setTimeout(() => {
    eventBus.emit("loading", false)
  }, time)
  
}

export const changeFont = (fontName) => {
  loadCssResource(`https://fonts.googleapis.com/css?family=${fontName}`)
  document.querySelector(".v-application").style.fontFamily = fontName
}

export const hexToRgb = (hex) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`: null
}

export const resetScroll = () => {
  window.scrollTo(0,0)
}

export const textTruncate = (text, chars) => {
  if (text.length > chars) {
    return `${text.slice(0, chars)}...`
  }

  return text
}

export const createCssOpacityColors = (theme) => {
  const documentRoot = document.documentElement
  const exceptions = ["logo"]
  const colorKeys = Object.keys(theme).filter(x => !exceptions.includes(x))

  colorKeys.forEach(colorName => {
    for (let i=1; i<10; i++) {
      documentRoot.style.setProperty(`--v-${colorName}-base-${i}`, `rgba(${hexToRgb(theme[colorName])}, ${i / 10})`)
    }
  })
}


export const waitForElm = (selector) => {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector))
        observer.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true
    })
  })
}

export const loadExternalScript = (url) => {
  if (url) {
    const newLink = document.createElement("script")
    newLink.type = "text/javascript"
    newLink.src = url
  
    document.head.appendChild(newLink)
    return document
  }
}

export const isMobileUserAgent = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export const pointsToPixels = (pts) => {
  return pts * 1.333
}

export const pixelsToPoints = (pixels) => {
  return pixels / 1.333
}

export default {
  loadCssResource,
  htmlVariableReplace,
  changeFavicon,
  changePageTitle,
  createModelComponent,
  createComponentData,
  generateFileDownload,
  globalLoading,
  changeFont,
  hexToRgb,
  resetScroll,
  textTruncate,
  createCssOpacityColors,
  waitForElm
}
