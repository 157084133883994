<template>
  <div>
    <div
      v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)"
      class="mb-4"
      :style="`width:${width.toString()}px !important;`"
    >
      <v-row dense class="ma-0 pa-2 secondary secondary-text--text fr-signature-status_bar-status_row" justify="space-between">
        <v-col cols="1">
          <v-progress-circular
            id="signature-status-bar-top_progress_circular"
            :color="actionStatus.completed === actionStatus.total ? 'success' : 'primary'"
            :value="progressBarValue"
            :rotate="-90"
            class="fr-signature-status_bar-progress-circular"
            size="50"
          >
            <v-icon
              v-if="actionStatus.completed === actionStatus.total"
              id="signature-status-bar-top_progress_circular_icon_completed"
              color="success"
              large
            >
              done
            </v-icon>

            <div v-else>
              <span
                id="signature-status-bar-top_progress_circular_current_signatures"
                class="fr-signature-status_bar-progress-circular__text-current"
              >
                {{ actionStatus.completed }}
              </span>

              <span
                id="signature-status-bar-top_progress_circular_total_signatures"
                class="fr-signature-status_bar-progress-circular__text secondary-text--text"
              >
                /{{ actionStatus.total }}
              </span>
            </div>
            
          </v-progress-circular>
        </v-col>

        <v-col align-self="center" class="px-4">
          <span
            v-if="actionStatus.completed === actionStatus.total"
            id="signature-status-bar-top_all_signatures_completed"
            class="secondary-text--text"
          >
            {{ mapping.allSignaturesCompleted }}
          </span>

          <span
            v-else
            id="signature-status-bar-top_pending_signatures"
            v-html="mapping.pendingSignaturesWarning.replace('%pending_signatures%', actionStatus.total - actionStatus.completed)"
            class="secondary-text--text">
          </span>
        </v-col>
        
        <v-col cols="4" align-self="center" class="text-right">
          <v-btn
            v-if="actionStatus.completed !== actionStatus.total"
            id="signature-status-bar-next_signature"
            @click="$emit('signCursor:prev')"
            class="primary--text"
            text
          >
            <span class="text-decoration-underline">{{ mapping.documentPanelNextInteraction }}</span>
            <v-icon small class="pl-2 mb-1" style="transform: rotate(90deg) !important">turn_right</v-icon>
          </v-btn>

          <v-btn
            v-else
            id="signature-status-bar_submit_signatures"
            class="primary"
            :loading="loading"
            @click="handleClickSubmit"
          >
            <v-icon small class="pr-2">done</v-icon>
            {{ mapping.submit }}
          </v-btn>

        </v-col>
      </v-row>
    </div>

<!-- MOBILE -->
    <div v-if="['xs', 'sm'].includes($vuetify.breakpoint.name)" :style="`width:${width.toString()}px !important;`">
      <v-row dense>
        <v-col cols="12" class="mt-4 fr-signature-status_bar__pending-signatures-container py-2 px-6">
          <v-row dense class="pt-1">
            <v-progress-linear
              color="primary"
              rounded
              height="7"
              :value="progressBarValue"
              background-color="white"
              class="fr-progress-linear"
              id="action-bar-mobile_progress_bar"
            />
          </v-row>

          <v-row dense class="pt-1">
            <v-col align-self="center" class="px-0">
              <span
                v-if="actionStatus.total - actionStatus.completed === 0"
                id="action-bar-mobile_all_signatures_completed"
                class="font-weight-bold secondary-text--text"
              >
                {{ mapping.allSignaturesCompleted }}
              </span>

              <span
                v-else
                class="font-weight-bold fr-signature-status_bar__pending-signatures-text"
                id="action-bar-mobile_pending_signatures"
              >
                {{ actionStatus.total - actionStatus.completed }} {{ mapping.pendingSignaturesText }}
              </span>
            </v-col>

            <v-col class="text-center px-0 ml-0" cols="12">
              <v-btn
                v-if="actionStatus.completed !== actionStatus.total"
                id="signature-status-bar-next_signature"
                @click="$emit('signCursor:prev')"
                class="primary--text"
                text
              >
                <span class="text-decoration-underline">{{ mapping.documentPanelNextInteraction }}</span>
                <v-icon small class="pl-2 mb-1" style="transform: rotate(90deg) !important">turn_right</v-icon>
              </v-btn>

              <v-btn
                v-else
                id="signature-status-bar-bottom_submit_signatures"
                class="primary"
                :loading="stateLoading"
                @click="handleClickSubmit"
              >
                <v-icon small class="pr-2">done</v-icon>
                {{ mapping.submit }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SignatureStatusTopBar",
    props: {
      width: Number,
      mapping: Object,
      actionStatus: Object,
      progressBarValue: Number,
      isLoading: Boolean
    },

    data() {
      return {
        loading: false
      }
    },

    computed: {
      stateLoading() {
        return this.loading
      }
    },

    methods: {
      handleClickSubmit() {
        this.loading = true
        this.$eventBus.emit("syncLoadings", true)

        this.$emit('signProcessCompleted')

        setTimeout(() => {
          this.loading = false
          this.$eventBus.emit("syncLoadings", false)
        }, 3500)
      }
    },

    watch: {
      isLoading(newVal) {
        this.loading = newVal
      }
    }
  }
</script>

<style scoped lang="scss">
  .fr-progress-linear {
    border: 1px solid var(--v-primary-base);
  }

  @media only screen and (max-width: 915px ) {
    .fr-signature-status_bar {
      position: -webkit-sticky !important;
      position: sticky !important;
      top: 0;

      &__pending-signatures {
        &-container {
          background-color: var(--v-secondary-base) !important;
          margin-top: 0 !important;
        }

        &-text {
          font-size: .95rem !important;
          color: var(--v-secondary-text-base) !important;
        }
      }

    }

  }
</style>