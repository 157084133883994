import { fetchEventSource } from '@microsoft/fetch-event-source';

const customFetch = async (input, init, responseCallback) => {
  const response = await fetch(input, init);

  const contentType = response.headers.get('Content-Type');
  if (contentType?.includes('text/html')) {
    if (typeof responseCallback === 'function') {
      responseCallback(response);
    }
  }

  return response;
}

const handleHtmlResponse = () => {
  console.warn("Response is text/html")
  location.reload();
}


export class ConnectionAlreadyOpened extends Error {
  constructor(message) {
    super(message);
    this.name = "ConnectionAlreadyOpened";
  }
}

export default class SSEService {
  constructor() {
    if (!SSEService.instance) {
      this.controller = new AbortController();
      SSEService.instance = this;

      return SSEService.instance;
    }

    throw new ConnectionAlreadyOpened('Conexion abierta, evito una nueva')

  }

  async connect(endpoint, method, headers, body, callbacks) {
    return await fetchEventSource(endpoint, {
      method,
      headers,
      fetch: (input, init) => customFetch(input, init, handleHtmlResponse),
      body: JSON.stringify(body),
      signal: this.controller.signal,
      ...callbacks
    });
  }

  disconnect() {
    this.controller.abort();
  }
}