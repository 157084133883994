<template>
  <SignatureStatusTopBar
    v-if="position === 'top'"
    :width="width"
    :actionStatus="actionStatus"
    :mapping="mapping"
    :progressBarValue="progressBarValue"
    :isLoading="loadingState"
    @signCursor:prev="handlePrevNextSign(false)"
    @signProcessCompleted="$emit('signProcessCompleted')"
  />

  <SignatureStatusBottomBar
    v-else
    :width="width"
    :actionStatus="actionStatus"
    :mapping="mapping"
    :isLoading="loadingState"
    @signCursor:prev="handlePrevNextSign(false)"
    @signProcessCompleted="$emit('signProcessCompleted')"
  />
</template>

<script>
import SignatureStatusBottomBar from "@/components/static/SignatureStatusBar/SignatureStatusBottomBar"
import SignatureStatusTopBar from "@/components/static/SignatureStatusBar/SignatureStatusTopBar"

export default {
  name: "SignatureStatusBar",
  components: { SignatureStatusBottomBar, SignatureStatusTopBar },

  props: {
    mapping: Object,
    width: Number,
    actionStatus: Object,
    position: String
  },

  data() {
    return {
      loadingState: false
    }
  },

  computed: {
    progressBarValue() {
      const v = this.actionStatus.completed * 100 / this.actionStatus.total
      return v === 0 ? 5 : v
    }
  },

  mounted() {
    this.$eventBus.on("syncLoadings", (value) => {
      this.loadingState = value
    })
  },

  methods: {
    handlePrevNextSign(prev) {
      this.$emit(`signField:${prev ? 'prev' : 'next'}`)
    },
  }
}
</script>

<style lang="scss" scoped>
  .fr-signature-status-bar {
    width: 100%;
    background-color: var(--v-secondary-base) !important;
  }
</style>
