import forge from "node-forge";

export const loadPublicKey = (certificateString) => {
  try {
    const cert = forge.pki.certificateFromPem(certificateString);

    return cert.publicKey;
  } catch (error) {
    console.error('Error loading certificate:', error);
  }
}

export const encryptData = (key, data) => {
  try {
    const encrypted = key.encrypt(data);

    return forge.util.encode64(encrypted);
  } catch (error) {
    console.error('Error encrypting data:', error);
  }
}