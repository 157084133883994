<template>
  <component
    :is="createdComponent"
    :viewVariables="{ documents: dataObject.documents, allowDownload: dataObject.allowDownload }"
    id="fr-intervener_finished-model"
    @downloadSingleFile="downloadSingleFile"
    @downloadAllFiles="downloadAllFiles"
  />
</template>

<script>
import { htmlVariableReplace, createModelComponent, createComponentData, generateFileDownload } from "@/utils/utils"
import { getModelResources } from "@/utils/modelUtils"
import { downloadSingleSignedFileIntervener, downloadAllFilesIntervener } from "@/services/generalService"

export default {
  name: "IntervenerFinishedWrapper",

  props: {
    dataObject: Object
  },

  data() {
    return {
      createdComponent: null,
    }
  },

  async created() {
    const modelResources = await getModelResources(this.dataObject.typology, this.dataObject.model)
    this.html = htmlVariableReplace(modelResources.html, modelResources.modelObject.variables, this.dataObject.mapping)
    this.modelObject = modelResources.modelObject
    const componentData = createComponentData(this.modelObject.requestVariables)

    this.createdComponent = createModelComponent(this.html, componentData)
    
    this.$emit("componentLoaded")

  },

  methods: {
    downloadSingleFile(eventData) {
      downloadSingleSignedFileIntervener(this.dataObject.currentHash, eventData.extra).then(response => {
        generateFileDownload(response.data.extra.fileContent, response.data.extra.fileName, response.data.extra.fileType)
      })
    },

    downloadAllFiles() {
      const token = JSON.parse(localStorage.getItem(this.dataObject.currentHash))
      // const token = JSON.parse(sessionStorage.getItem(this.dataObject.currentHash))

      downloadAllFilesIntervener(this.dataObject.currentHash, token.value).then(response => {
        generateFileDownload(response.data.extra.fileContent, response.data.extra.fileName, response.data.extra.fileType)
      })
    }
  }
}
</script>
