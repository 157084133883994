import Axios from "@/plugins/axios"

const default_url = `${process.env.VUE_APP_RESOURCES_ENDPOINT}models/`

export const fetchModelHTML = (modelType, modelName) => {
  return Axios.get(`${default_url}${modelType}/${modelName}/template.html`)
}

export const fetchModelDefinition = async (modelType, modelName) => {
  return Axios.get(`${default_url}${modelType}/${modelName}/config.json`)
}

export default {
  fetchModelHTML,
  fetchModelDefinition
}
