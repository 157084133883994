import Axios from 'axios'

Axios.interceptors.request.use(
  config => {
    // config.headers["hash"] = store.state.currentHash
    return config
  },

  error => {
    Promise.reject(error)
  }
)

export default Axios
