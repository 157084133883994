<template>
  <component
    id="fr-action-bar-model" 
    :is="createdComponent"
    :viewVariables="{
      width,
      documentName,
      DESKTOP_ZOOM_SELECT_VALUES,
      currentZoom
    }"
    @zoomClick="handleZoomClick"
    @fileDownload="handleFileDownload"
  />
</template>

<script>
import { htmlVariableReplace, createModelComponent, createComponentData } from "@/utils/utils"
import { getModelResources } from "@/utils/modelUtils"
import { DESKTOP_ZOOM_SELECT_VALUES } from "@/constants/documentConstants"

export default {
  name: "ActionBarWrapper",
  props: {
    typology: String,
    model: String,
    mapping: Object,
    width: Number,
    documentName: String,
  },

  data() {
    return {
      DESKTOP_ZOOM_SELECT_VALUES,
      createdComponent: null,
      currentZoom: 100,
    }
  },

  async created() {
    const modelResources = await getModelResources(this.typology, this.model)
    this.html = htmlVariableReplace(modelResources.html, modelResources.modelObject.variables, this.mapping)
    this.modelObject = modelResources.modelObject
    const componentData = createComponentData(this.modelObject.requestVariables)

    this.createdComponent = createModelComponent(this.html, componentData)
    
    this.$emit("componentLoaded")

  },

  methods: {
    handleZoomClick(eventData) {
      
      if (eventData.extra == "+" && this.DESKTOP_ZOOM_SELECT_VALUES.indexOf(this.currentZoom) + 1 < this.DESKTOP_ZOOM_SELECT_VALUES.length) {
        this.currentZoom = this.DESKTOP_ZOOM_SELECT_VALUES[this.DESKTOP_ZOOM_SELECT_VALUES.indexOf(this.currentZoom) + 1]

        this.$eventBus.emit("zoomChange", '+')

      } else if(eventData.extra == "-" && this.DESKTOP_ZOOM_SELECT_VALUES.indexOf(this.currentZoom) - 1 >= 0) {
        this.currentZoom = this.DESKTOP_ZOOM_SELECT_VALUES[this.DESKTOP_ZOOM_SELECT_VALUES.indexOf(this.currentZoom) - 1]

        this.$eventBus.emit("zoomChange", '-')

      } else if (eventData.extra != "+" && eventData.extra != "-") {
        this.currentZoom = eventData.extra
        this.$eventBus.emit("zoomChange", eventData.extra)
      }
    },

    handleFileDownload() {
      this.$eventBus.emit("fileDownload")
    }
  }
}
</script>

<style lang="scss" scoped>

</style>