import {
  VAlert,
  VAppBar,
  VAppBarNavIcon,
  VAutocomplete,
  VBtn,
  VBtnToggle,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VCheckbox,
  VCol,
  VContainer,
  VDialog,
  VDivider,
  VExpandTransition,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VFooter,
  VIcon,
  VImg,
  VList,
  VListItem,
  VListItemAction,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle,
  VMain,
  VMenu,
  VOtpInput,
  VProgressLinear,
  VRadio,
  VRadioGroup,
  VRow,
  VSelect,
  VSheet,
  VSlideXTransition,
  VSpacer,
  VTextField,
  VToolbar,
  VToolbarTitle
} from 'vuetify/lib'

export default {
  VAlert,
  VAutocomplete,
  VBtn,
  VCard,
  VCardTitle,
  VCardSubtitle,
  VCardText,
  VCardActions,
  VCol,
  VContainer,
  VDivider,
  VExpandTransition,
  VFooter,
  VIcon,
  VImg,
  VMain,
  VRow,
  VSelect,
  VTextField,
  VToolbar,
  VBtnToggle,
  VMenu,
  VList,
  VListItem,
  VListItemTitle,
  VListItemContent,
  VListItemSubtitle,
  VListItemAction,
  VOtpInput,
  VAppBar,
  VAppBarNavIcon,
  VSpacer,
  VToolbarTitle,
  VProgressLinear,
  VDialog,
  VSlideXTransition,
  VSheet,
  VCheckbox,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VRadioGroup,
  VRadio
}
