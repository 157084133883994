import forge from "node-forge"

export default class AutofirmaManager {
  constructor (algorithm="SHA512withRSA", format="Adobe PDF") {
    this.autoscript = window.AutoScript || window.Autoscript
    this.URL_BASE_SERVICES = "http://appprueba:8080"
    this.autoscript.cargarAppAfirma()
    this.autoscript.setServlets(
      `${this.URL_BASE_SERVICES}/afirma-signature-storage/StorageService`,
      `${this.URL_BASE_SERVICES}/afirma-signature-retriever/RetrieveService`
    )

    this.algorithm = algorithm
    this.format = format

  }

  /**
   * Convert js object to \n string parameters
   * @param {Object} parameterObject - Js object to be converted.
   */
  parametersObjectToString (parameterObject) {
    let res = ""

    for (const [key, value] of Object.entries(parameterObject)) {
      res += `${key}=${value}\n`
    }

    return res.trim()
  }

  /**
   * Document sign
   * @param {string} base64Document - The document.
   * @param {string} algorithm - Algorithm.
   * @param {string} format - Document Format.
   * @param {string} params - Signature parameters, separated by \n.
   * @param {Function} successCallback - Function called if signature is succesfully.
   * @param {Function} errorCallback - Function called if signature returns any error.
   */
  signDocument(base64Document, params, successCallback=null, errorCallback=null) {
    try {
      this.autoscript.setStickySignatory(true)
      const convertedParams = this.parametersObjectToString(params)
      this.autoscript.sign(
        base64Document,
        this.algorithm,
        this.format,
        convertedParams,
        successCallback,
        errorCallback
        
      )
    } catch(error) {
      console.error("AUTOSCRIPT ERROR:", error)
    }
  }

  /**
   * Default callback for sign success
   * @param {string} signedDocumentBase64 - Signed document in base64.
   * @param {string} certificateB64 - Public part of the used certificate in base64.
   * @param {Object} extraData - Extra data.
   */
  onSignSuccess(signedDocumentBase64, certificateB64, extraData) {
    console.log("Firma OK", signedDocumentBase64)
    console.log("certificate", certificateB64)
    console.log("Data", extraData)
  }

  /**
   * Default callback for sign error
   * @param {string} errorType - Error type.
   * @param {string} errorMessage - Error message.
   */
  onError(errorType, errorMessage) {
    // Operacion cancelada por el usuario
    // Timeout - no se pudo contactar con autofirma (caso que salga la ventana en el navegador para abrir autofirma y se pulse cancelar)
    console.error(errorType)
    console.error(errorMessage)
  }


  checkCertificateIdentity(certificateB64, identificationNumber) {
    const der = forge.util.decode64(certificateB64)
    const certificateObject = forge.pki.certificateFromAsn1(forge.asn1.fromDer(der))
    return Boolean(certificateObject.subject.attributes.find(x => x.value.includes(identificationNumber)))
  }

}