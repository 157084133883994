<template>
  <div>
    <v-row dense>
      <v-col class="pa-0">
        <v-card>
          <pdf
            :id="`pdfcontainer-${page}`"
            :src="document"
            :page="page"
            class="pdf-page"
            @num-pages="setTotalPages"
            @page-loaded="handlePageRendered"
          />
        </v-card>
      </v-col>

    </v-row>
    <v-row dense justify="center" class="mt-2">
      <v-col align-self="center" class="pa-0 text-right">
        <v-btn
          text
          icon
          color="var(--v-primary-base)"
          :disabled="page === 1"
          @click="movePage('-')"
        >
          <v-icon>chevron_left</v-icon>
        </v-btn>
      </v-col>

      <v-col align-self="center" class="pa-0 text-center" style="color: var(--v-secondary-text-base)">
        {{ mapping.documentPanelPage }} {{ page }}/{{ totalPages }}
      </v-col>

      <v-col align-self="center" class="pa-0 text-left">
        <v-btn
          text
          icon
          color="var(--v-primary-base)"
          :disabled="page === totalPages"
          @click="movePage('+')"
        >
          <v-icon>chevron_right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import pdf from 'vue-pdf'
import Vue from "vue"
import vuetify from "@/plugins/vuetify"
import DocumentAction from "@/components/general/DocumentAction"
export default {
  name: "MonoPagePDFRenderer",
  components: { pdf },
  props: ["document", "documentActions", "mapping", "documentSize", "pendingSignatures", "signaturesApplied", "single_click_signature", "graphometricSignatureObject"],

  data() {
    return {
      totalPages: null,
      page: 1,
      documentCompleted: false
    }
  },

  created() {
    this.$eventBus.on("nextSignature", this.focusNextSignature)
  },

  mounted() {
    const newDiv = document.createElement("div")
    newDiv.classList.add("action-layer")
    this.$el.querySelector((".pdf-page")).appendChild(newDiv)
  },

  methods: {
    movePage(direction) {
      if (direction === "-" && this.page === 1) return
      if (direction === "+" && this.page === this.totalPages) return

      this.page += (direction === "-") ? -1 : 1
    },

    goToPage(pageNumber) {
      this.page = pageNumber
    },

    setTotalPages(nPages) {
      this.totalPages = nPages
    },

    clearActionLayer() {
      this.$el.querySelector(".action-layer").replaceChildren()
    },

    focusNextSignature() {
      const nextSignature = this.documentActions.find(x => x.id === this.pendingSignatures[0])
      if (!nextSignature) return

      this.goToPage(nextSignature.page)
      setTimeout(() => {
        this.$el.querySelector(`#${nextSignature.id}`).scrollIntoView({block: "center", behavior: "smooth"})
      }, 200)
    },

    handlePageRendered(pageNumber) {
      this.clearActionLayer()
      // const pageActions = this.documentActions.filter(x => this.pendingSignatures.includes(x.id) && x.page === pageNumber)
      const pageActions = this.documentActions.filter(x => x.page === pageNumber)

      const actionLayer = this.$el.querySelector(".action-layer")

      if (!this.single_click_signature) {
        for (const pageAction of pageActions) {
          const newDiv = document.createElement("div")
          newDiv.classList.add("action-wrapper")
          actionLayer.appendChild(newDiv)

          let completedElement

          if (this.graphometricSignatureObject) {
            completedElement = document.createElement("img")

            completedElement.style.display = "block"
            completedElement.style.position = "relative"
            completedElement.style.backgroundColor = ""
            completedElement.style.objectFit = "contain"
            completedElement.src = this.graphometricSignatureObject.image
          }

          let actionIsCompleted

          if (pageAction.type.includes("graphometric")) {
            actionIsCompleted = this.graphometricSignatureObject && !this.pendingSignatures.includes(pageAction.id)
          } else {
            actionIsCompleted = !this.pendingSignatures.includes(pageAction.id)
          }

          const documentAction = new Vue({
            vuetify,
            render: h => h(DocumentAction, {
              props: {
                action: pageAction,
                mapping: this.mapping,
                actionNumber: this.documentActions.findIndex(x => x.id === pageAction.id) + 1,
                nTotalOfActions: this.documentActions.length,
                nOfAppliedSignatures: this.signaturesApplied,
                pageContainer: actionLayer.parentElement,
                documentSize: this.documentSize,
                isCompleted: actionIsCompleted,
                completedElementSlot: completedElement
              }
            })
          })

          documentAction.$mount(actionLayer.querySelector(".action-wrapper"))
        }
      }


      this.$emit("rendered")
    }
  },

  watch: {
    page() {
      if (this.documentCompleted || (!this.documentCompleted && this.page === this.totalPages)) {
        this.documentCompleted = true
        this.$eventBus.emit("documentScrolled")
      }
    }
  }
}
</script>

<style lang="scss">
  .annotationLayer > .linkAnnotation {
    display: none !important;
  }
</style>