import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import Axios from '@/plugins/axios'
import mitt from "mitt"

import SignatureRejectionLink from "@/components/static/SignatureRejectionLink.vue"

Vue.config.productionTip = false
Vue.prototype.$axios = Axios
Vue.prototype.$eventBus = mitt()

Vue.component('SignatureRejectionLink', SignatureRejectionLink);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
