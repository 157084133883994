<template>
  <v-fade-transition>
    <component
      v-if="!isIdentified"
      :is="createdComponent"
      id="fr-identification-model"
      :viewVariables="{
        otpError,
        requestNewOtpError,
        loading,
        responseMessage,
        otpSent,
        VERIDAS_URL,
        documentImageToken,
        veridasTokenError,
        veridasError,
        veridasStep
      }"
      ref="otpcomponent"
      @submit="handleSubmit"
      @requestNewOtp="handleRequestNewOtp"
      @requestOtp="requestOtp"
      @requestNewVeridasId="handleRequestNewVeridasIdentification"
      @changeStep="changeStep"
    />
  </v-fade-transition>
</template>

<script>
import { htmlVariableReplace, createModelComponent, createComponentData, globalLoading, waitForElm } from "@/utils/utils"
import { getModelResources, otpCustomBehaviour } from "@/utils/modelUtils"
import { sendData, getNewOtp, getCertificateAuthenticationURL, getDocumentImageIdentificationToken, sendDocumentImageResult, getAutenticacaoURL } from "@/services/generalService"
import typologies from "@/constants/typologies"
import { VERIDAS_URL } from "@/constants/veridas"

  export default {
    name: "IdentificationWrapper",

    props: {
      dataObject: Object,
    },


    data() {
      return {
        VERIDAS_URL,
        modelObject: null,
        html: null,
        createdComponent: null,
        isIdentified: false,
        loading: false,
        responseMessage: null,
        otpError: null,
        requestNewOtpError: null,
        otpSent: false,

        documentImageToken: null,
        validationId: null,
        eventArray: [],
        veridasTokenError: false,
        veridasError: false,
        veridasStep: "info"
      }
    },

    async created() {
      const modelResources = await getModelResources(this.dataObject.typology, this.dataObject.model)
      this.html = htmlVariableReplace(modelResources.html, modelResources.modelObject.variables, this.dataObject.mapping)
      this.modelObject = modelResources.modelObject

      const componentData = createComponentData(this.modelObject.requestVariables)

      const onCreate = () => {
        waitForElm(".v-otp-input input").then(elm => {
          elm.focus()
          otpCustomBehaviour(".fr-otp-identification_model-1__input-container .v-otp-input input", this.$refs.otpcomponent)
        })
      }

      this.createdComponent = createModelComponent(this.html, componentData, onCreate)

      this.$emit("componentLoaded")

      this.$eventBus.on("iframeMessage", (event) => {
        this.handleIframeMessageEvent(event)
      })
    },

    methods: {
      async handleSubmit(eventData) {
        this.otpError = this.responseMessage = null
        this.loading = true

        const payload = Object.assign({}, eventData.componentData.returnData)

        switch(this.dataObject.typology) {
          case typologies.CERTIFICATE_IDENTIFICATION: {
            this.certificateIdentification()
            break
          }
            
          
          case typologies.RELIABLE_IDENTIFICATION: {
            const selectedMethod = eventData.extra

            if (selectedMethod == "certificate") {
              this.certificateIdentification()

            } else {
              const response = await getAutenticacaoURL(this.dataObject.currentHash)
              window.location = response.data
            }
            break
          }
            

          default: {
            const response = await sendData(this.dataObject.currentHash, this.dataObject.typology, { action: this.dataObject.typology, parameters: payload })
            if (response.data.error == 0) {
              this.$emit("identificationCompleted", false)
              globalLoading(this.$eventBus, 500)

            } else {
              setTimeout(()=> {
                this.otpError = this.responseMessage = response.data.message
                this.loading = false
              }, 1000)

            }
          }

        }

      },

      async handleRequestNewOtp() {
        const response = await getNewOtp(this.dataObject.currentHash)
        this.otpError = response.data.error === 0 ? response.data.message : null
      },

      async requestOtp() {
        const response = await getNewOtp(this.dataObject.currentHash)
        const timeout = setTimeout(() => {
          this.otpSent = true
        }, 500)

        this.requestNewOtpError = response.data.error == 0 ? timeout : response.data.message
      },

      async requestDocumentImageToken() {
        const response = await getDocumentImageIdentificationToken(this.dataObject.currentHash)

        if (response.data.error != 0) {
          return response.data.message
        }

        this.documentImageToken = response.data.message.token
        this.validationId = response.data.message.validationId
      },

      async sendDocumentImageResult(validationId, eventCode) {
        const response = await sendDocumentImageResult(this.dataObject.currentHash, eventCode, validationId)
        if (response.data.error == 0) {
          this.$emit("identificationCompleted")
        } else {
          this.veridasError = response.data.message
          this.$eventBus.off("iframeMessage")
        }
      },

      async certificateIdentification() {
        const response = await getCertificateAuthenticationURL(this.dataObject.currentHash)
        window.location = `${response.data}?back=${window.location.href}`
      },

      handleIframeMessageEvent(event) {
        const validationId = event.data.additionalData.validationId ? event.data.additionalData.validationId : this.validationId
        this.sendDocumentImageResult(validationId, event.data.code)

      },

      handleRequestNewVeridasIdentification() {
        this.veridasError = false
        this.$emit("identificationCompleted")
      },

      async changeStep() {
        const requestTokenError = await this.requestDocumentImageToken()
        if (requestTokenError) {
          this.veridasTokenError = requestTokenError
        } else {
          this.veridasStep = "iframeValidation"
        }
      }
    }
  }
</script>
