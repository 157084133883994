<template>
  <component
    v-if="!isIdentified"
    :is="createdComponent"
    id="fr-accept-or-reject-model"
    :viewVariables="{ responseMessage, loading }"
    @submit="handleSubmit"
  />
</template>

<script>
import { htmlVariableReplace, createModelComponent, createComponentData } from "@/utils/utils"
import { getModelResources } from "@/utils/modelUtils"
import { sendData } from "@/services/generalService"
import typologies from "@/constants/typologies"

  export default {
    name: "AcceptOrRejectTransaction",

    props: {
      dataObject: Object,
    },

    data() {
      return {
        modelObject: null,
        html: null,
        createdComponent: null,
        isIdentified: false,
        responseMessage: null,
        loading: false,
      }
    },

    async created() {
      const modelResources = await getModelResources(this.dataObject.typology, this.dataObject.model)
      this.html = htmlVariableReplace(modelResources.html, modelResources.modelObject.variables, this.dataObject.mapping)
      this.modelObject = modelResources.modelObject

      const componentData = createComponentData(this.modelObject.requestVariables)

      this.createdComponent = createModelComponent(this.html, componentData)

      this.$emit("componentLoaded")
    },

    methods: {
      async handleSubmit(eventData) {
        this.loading = true
        const ppr = eventData.extra
        const action = ppr ? "accepted" : "rejected"

        const headers = {
          ppr: action,
          ppt: this.dataObject.typology
        }

        const response = await sendData(
          this.dataObject.currentHash,
          typologies.PREPEND_PAGE,
          {},
          headers
        )

        if (response.data.error == 0) {
          this.$emit("stepCompleted")
        }

      }
    }

  }
</script>
