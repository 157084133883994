<template>
  <div
    class="fr-sign-tag"
  >
    <div class="fr-sign-tag__container my-auto pa-2" :style="getStyle()">
      <v-icon small>border_color</v-icon>
      {{ mapping.documentPanelSignatureTag }} {{signatureNumber}}
    </div>
    <v-icon class="fr-sign-tag__arrow text-right" :color="getStyle(true)" style="width:30px;">arrow_right</v-icon>
  </div>
</template>

<script>
import { hexToRgb } from "@/utils/utils"
  export default {
    name: "SignTag",
    props: {
      mapping: Object,
      signButtonPosition: Object,
      documentSize: Object,
      signatureNumber: String
    },

    methods: {
      getStyle(color=false) {
        if (color) {
          return `rgba(${hexToRgb(this.$vuetify.theme.themes.light.primary)}, 0.25)`
        }
        return `background-color: rgba(${hexToRgb(this.$vuetify.theme.themes.light.primary)}, 0.25);`
      }
    }
  }
</script>

<style lang="scss" scoped>
  .fr-sign-tag {
    position: absolute;
    display: inline-flex;
    align-content: flex-start;
    justify-content: center;
    font-size: 1rem !important;
    margin-top: -30px;
    color: var(--v-text-color-base);

    &__container {
      width: auto;
      max-width: 200px;
      min-width: 200px;
    }

    &__arrow {
      font-size: 2.813rem;
    }

  }

  @media only screen and (max-width: 1400px) {
    .fr-sign-tag__container {
      visibility: hidden;
    }

    .fr-sign-tag__arrow {
      font-size: 4rem !important;
    }
  }

  @media only screen and (max-width: 915px) {
    .fr-sign-tag {
      margin-top: -42px !important;
    }
  }


</style>