<template>
  <GenericDialog
    :persistent="false"
    @click:outside="$emit('click:outside')"
  >
    <v-form>
      <v-card>
        <v-card-subtitle>
          <v-row dense justify="center" class="pb-0">
            <v-col cols="12" class="text-center">
              <v-icon id="rejection-panel-icon" size="65" color="primary">cancel_presentation</v-icon>
            </v-col>
            <v-col class="text-center ">
              <h1 id="rejection-panel-title" class="font-style-title">{{ dataObject.mapping.rejectionReasonTitle }}</h1>
              <v-divider/>
              <p id="rejection-panel-text" class="font-style-introduction">{{ dataObject.mapping.rejectionReasonExplain }}</p>
            </v-col>
          </v-row>
        </v-card-subtitle>

        <v-card-text class="pb-2">
          <v-row dense justify="center">
            <v-col class="text-center">
              <v-select
                outlined
                v-model="rejectionReason"
                :items="rejectionReasons"
                :placeholder="dataObject.mapping.rejectionReasonTitle"
              />
            </v-col>
          </v-row>

          <v-row dense v-if="rejectionReason == rejectionReasons[rejectionReasons.length - 1] && (dataObject.rejectionReason == 1)">
            <v-col>
              <v-textarea
                v-model="rejectionReasonText"
                autofocus
                outlined
                full-width
                clearable
                no-resize
                :counter="rejectionReasonTextCharLimit"
                :maxlength="rejectionReasonTextCharLimit"
                :placeholder="dataObject.mapping.rejectionReasonTextAreaPlaceholder"
            />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center">
            <v-btn
              class="fr-rejection-panel-button_reject"
              :disabled="buttonDisabled"
              @click="onReject"
            >
              {{ dataObject.mapping.rejectionButtonText }}
            </v-btn>

          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </GenericDialog>
</template>

<script>
import GenericDialog from "@/components/general/GenericDialog"

  export default {
    name: "SignatureRejectionManager",
    components: { GenericDialog },
    props: {
      dataObject: Object
    },

    data() {
      return {
        dialogOpen: true,
        rejectionReason: null,
        rejectionReasonText: null,
        rejectionReasonTextCharLimit: 500
      }
    },

    computed: {
      rejectionReasons() {
        return this.dataObject.mapping.rejectionReasonComboOptions.split("|")
      },

      buttonDisabled() {
        if (!this.dataObject.rejectionReason) {
          return this.rejectionReason === null
        }

        const isOtherReason = this.rejectionReason === this.rejectionReasons[this.rejectionReasons.length - 1]
        const hasReasonAndText = this.rejectionReason && this.rejectionReasonText

        return !this.rejectionReason || (isOtherReason && !hasReasonAndText)
      }
    },
    
    methods: {
      onReject() {
        this.$emit("rejectDocument", this.rejectionReasonText ? this.rejectionReasonText : this.rejectionReason)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .fr-rejection-panel-button_reject {
    background-color: var(--v-primary-base) !important;
    color: var(--v-primary-text-base) !important;
  }
</style>