<template>
  <component
    v-if="modelObject"
    :is="createdComponent"
    id="fr-sign-model"
  >
    <SignPDFManager
      :file="currentFile"
      :dataObject="dataObject"
      :actionBarModel="modelObject.actionBarModel"
      :singleClickSignatureBarModel="modelObject.singleClickSignatureBarModel"
      :graphometricSignatureObject="graphometricSignatureObject"
      v-model="userActions"
      @changeSize="changeSize"
      @submitSignatures="submitSignatures"
      @showGraphometricSignatureDialog="handleGraphometricDialog"
    />

    <ConfirmSignaturesDialog
      v-if="showSignatureDialog"
      :mapping="dataObject.mapping"
      @confirmSignatures="submitSignatures"
      @back="showSignatureDialog=false"
    />

    <OtpSignatureManager
      v-if="otpSignatureDialog"
      :dataObject="dataObject"
      :userActions="userActions"
      :mode="otpSignatureDialogMode"
      :isAMA="otpManagerIsAma"
      @signCompleted="emitSignCompleted"
    />

    <AMASignatureManager
      v-if="amaDialog"
      :dataObject="dataObject"
      @otpSent="handleAmaOtpSent"
    />

    <GraphometricSignatureManager
      v-if="graphometricSignatureDialog"
      :dataObject="dataObject"
      :userActions="userActions"
      @confirmSignatures="handleGraphometricSignature"
      @back="graphometricSignatureDialog=false"
    />

    <AutofirmaInformationDialog
      v-if="autofirmaInfoDialog"
      :mapping="dataObject.mapping"
      :error="autofirmaDialogError"
      :loading="autofirmaDialogLoading"
      @confirmSignatures="handleAutofirmaProcess"
      @back="resetAutofirmaProcess"
    />

  </component>
</template>

<script>
import { htmlVariableReplace, createModelComponent } from "@/utils/utils"
import { getModelResources } from "@/utils/modelUtils"
import SignPDFManager from "@/components/SignPDFManager"
import DOCUMENT_CONSTANTS from "@/constants/documentConstants"
import ConfirmSignaturesDialog from "@/components/static/ConfirmSignaturesDialog"
import OtpSignatureManager from "@/components/OtpSignatureManager"
import GraphometricSignatureManager from "@/components/GraphometricSignatureManager"
import { sendAutofirmaDocument } from "@/services/generalService"
import AutofirmaManager from "@/utils/AutofirmaManager"
import { signatureRubricImage } from "@/constants/autofirma"
import AutofirmaInformationDialog from "@/components/static/AutofirmaInformationDialog"
import SSEService, { ConnectionAlreadyOpened } from "@/utils/SSEService"
import AMASignatureManager from "@/components/AMASignatureManager.vue"

  export default {
    name: "SignWrapper",
    components: {
      SignPDFManager,
      OtpSignatureManager,
      ConfirmSignaturesDialog,
      AutofirmaInformationDialog,
      GraphometricSignatureManager,
      AMASignatureManager
    },

    props: {
      dataObject: Object
    },

    computed: {
      currentFile() {
        const f = this.dataObject.document
        f.actions = this.dataObject.actions

        return f
      }
    },

    data() {
      return {
        DOCUMENT_CONSTANTS,
        modelObject: null,
        html: null,
        createdComponent: null,
        width: null,
        height: null,
        showSignatureDialog: false,
        otpSignatureDialog: false,
        otpSignatureDialogMode: null,
        otpManagerIsAma: false,
        graphometricSignatureDialog: false,
        userActions: {},
        autofirmaInfoDialog: false,
        autofirmaDialogError: false,
        autofirmaDialogLoading: false,
        amaDialog: false,

        autofirmaIntervenerId: null,

        graphometricSignatureObject: null,
        currentGraphometricId: null,
        eventSource: null,
        signature_type: null
      }
    },

    async created() {
      const modelResources = await getModelResources(this.dataObject.typology, this.dataObject.model)
      this.html = htmlVariableReplace(modelResources.html, modelResources.modelObject.variables, this.dataObject.mapping)
      this.modelObject = modelResources.modelObject
      const componentData = {}

      this.createdComponent = createModelComponent(this.html, componentData)

      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          this.width = 1000
          this.height = 1000
          break

        case "xs":
        case "sm":
          this.width = 330
          this.height = 467
          break
      }

      this.$eventBus.on("showSignatureDialog", () => {
        if (this.dataObject.signaturePopup && this.dataObject.signaturePopup !== "") {
          this.handleShowSignatureDialog(true)
        }
      })

    },
    methods:{
      renderSignatureImage(action, image) {
        let button = this.$el.querySelector(`#${action.id} button`)

        if (!button) {
          button = this.$el.querySelector(`#${action.id}_${action.page} button`)
        }

        button.parentElement.querySelector(".fr-sign-tag")?.classList.add("d-none")
        document.querySelector(`#signaturetag_${action.id}`)?.classList.add("d-none")

        button.parentElement.classList.remove("fr-signature-button-pending")

        const img = document.createElement("img")
        img.setAttribute("style", button.getAttribute("style"))
        img.style.display = "block"
        img.style.position = "relative"
        img.style.backgroundColor = ""
        img.style.objectFit = "contain"
        img.src = image

        button.replaceWith(img)
      },

      handleGraphometricDialog(signatureId) {
        this.currentGraphometricId = signatureId
        if (!this.graphometricSignatureObject) {
          this.graphometricSignatureDialog = true

        } else {
          const action = this.dataObject.actions.find(x => x.id === this.currentGraphometricId)
          const image = this.graphometricSignatureObject.image
          this.renderSignatureImage(action, image)
          this.userActions.signature_graphometric = { ...this.userActions.signature_graphometric, ...{ [action.id]: true } }
          this.$eventBus.emit("increaseAppliedSignatures", action.id)
        }
      },

      handleGraphometricSignature(signatureData) {
        const action = this.dataObject.actions.find(x => x.id === this.currentGraphometricId)
        this.graphometricSignatureObject = signatureData.signature_graphometric

        const { image, coords, initTimestamp, duration } = this.graphometricSignatureObject

        this.renderSignatureImage(action, image)

        this.userActions["signature_graphometric"] = { ...this.userActions["signature_graphometric"], ...{ [action.id]: true } }
        this.userActions = {
          ...this.userActions,
          ...{ signature_graphometric_image: image.split(",")[1], coords, initTimestamp, duration }
        }

        this.graphometricSignatureDialog = false
        this.$eventBus.emit("increaseAppliedSignatures", action.id)

      },

      async handleAutofirmaProcess() {
        this.autofirmaDialogLoading = true

        const autofirma = new AutofirmaManager()
        const signatureParameters = {
          signaturePage: this.dataObject.actions[0].page,
          signatureField: this.dataObject.actions[0].id,
          layer2FontFamily: 1,
          layer2FontSize: 9,
          layer2Text: "$$SUBJECTCN$$",
          signatureRubricImage: signatureRubricImage,

          signaturePositionOnPageUpperLeftX: 100,
          signaturePositionOnPageUpperLeftY: 100

        }

        const successCallback = async (signedDocumentBase64, certificateB64) => {
          const payload = { file_content: signedDocumentBase64, signer_certificate: certificateB64 }
          const response = await sendAutofirmaDocument(this.dataObject.currentHash, payload)

          if (response.data.error !== 0) {
            this.autofirmaDialogError = "authentication_error"
            this.autofirmaDialogLoading = false
          }
        }

        const errorCallback = (errorType, errorMessage) => {
          console.warn(errorType)
          console.warn(errorMessage)

          this.autofirmaDialogLoading = false
          switch (errorType) {
            case "java.util.concurrent.TimeoutException":
              this.autofirmaDialogError = "timeout"
              break;

            case "es.gob.afirma.core.AOCancelledOperationException":
              this.autofirmaDialogError = "canceled_by_user"
              break;
          
            default:
              break;
          }          
        }

        autofirma.signDocument(
          this.dataObject.document.file_content,
          signatureParameters,
          successCallback,
          errorCallback
        )
      },

      resetAutofirmaProcess() {
        this.autofirmaInfoDialog = false
        this.autofirmaDialogError = false
        location.reload()
      },

      changeSize(newSize){
        this.width = newSize.width
        this.height = newSize.height
      },

      // async submitSignatures(userActions) {
      //   const actions = userActions || this.userActions
      //   this.loading = true
      //
      //   const response = await sendData(this.dataObject.currentHash, "document", actions)
      //   this.$eventBus.off("fileDownload")
      //   const [responseMessage, extraParameters] = response.data.message.split("#")
      //
      //   if (responseMessage === "document_ko") {
      //     alert("Error al firmar el documento")
      //
      //   } else if (responseMessage === "requires_otp") {
      //     this.otpSignatureDialog = true
      //
      //   } else if (responseMessage === "otp_limit_reached") {
      //     this.otpSignatureDialog = true
      //     this.otpSignatureDialogMode = "limit-reached"
      //
      //   } else if (responseMessage === "requires_certificate") {
      //     this.autofirmaInfoDialog = true
      //     this.autofirmaIntervenerId = extraParameters
      //
      //
      //   } else {
      //     this.emitSignCompleted()
      //   }
      // },


      async submitSignatures(userActions) {
        const actions = userActions || this.userActions
        const url = `${process.env.VUE_APP_ENDPOINT}signer.php`
        const headers = { hash: this.dataObject.currentHash, document: this.dataObject.document.file_name }

        try {
          const eventSourceService = new SSEService()
          const callbacks = this.createCallbacks()
          this.eventSource = eventSourceService.connect(url, "POST", headers, actions, callbacks)
        } catch (error) {
          if (error instanceof ConnectionAlreadyOpened) {
            console.warn("ConnectionAlreadyOpened")
            location.reload()
          } else {
            console.error("Error while establishing connection:", error)
          }
        }
      },

      createCallbacks() {
        return {
          // onopen: (response) => {
          //   console.info("Connection established:", response)
          // },
          onmessage: (e) => {
            this.handleMessageReceived(e)
          },
          onclose: (/*e*/) => {
            // console.log("Connection closed by the server:", e)
            location.reload()
          },
          onerror: (err) => {
            console.error("Error from server:", err)
          }
        }
      },

      handleMessageReceived(e) {
        // console.log("Received message:", e.event, e.data)
        if (e.event === "signature") {
          switch (e.data) {
            case "otp":
              this.signature_type = e.data
              this.handleOtpSignature()
              break

            case "ama":
              this.handleAMASignature()
              break

            case "certificate":
              this.signature_type = e.data
              this.autofirmaInfoDialog = true
              break

            case "signature_failed":
              if (this.signature_type === "certificate") {
                this.autofirmaDialogError = "authentication_error"
                this.autofirmaDialogLoading = false
              }

              break

            case "none":
              location.reload()
          }

        }
      },

      handleOtpSignature() {
        this.otpSignatureDialog = true
      },

      handleAmaOtpSent() {
        this.reset()
        this.otpManagerIsAma = true
        this.handleOtpSignature()
      },

      handleAMASignature() {
        if (this.otpManagerIsAma) return
        this.amaDialog = true
      },

      reset() {
        this.currentGraphometricId = null
        this.graphometricSignatureObject = null
        this.otpSignatureDialog = false
        this.otpSignatureDialogMode = null
        this.amaDialog = false
      },

      emitSignCompleted() {
        this.$eventBus.emit("signCompleted")
      },

      handleShowSignatureDialog(value) {
        this.showSignatureDialog = value
      }
    },

    beforeUnmount() {
      this.$eventBus.$off("confirmSignatures", () => {
        this.emitSignCompleted()
      })

      this.$eventBus.off("showSignatureDialog", () => {
        this.handleShowSignatureDialog()
      })
    }

  }
</script>
