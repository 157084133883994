<template>
  <v-app>
    <v-main class="background-color">
      <router-view :currentHash="currentHash" />
    </v-main>
  </v-app>
</template>

<script>
import { changePageTitle, loadExternalScript } from "@/utils/utils"
export default {
  name: 'App',

  async created() {
    this.currentHash = this.$route.params.hash
    changePageTitle("CertySign")
    loadExternalScript(`${process.env.VUE_APP_RESOURCES_ENDPOINT}scripts/autoscript.js`)

    // window.addEventListener('beforeunload', () =>  {
    //   sessionStorage.removeItem('__lock')
    // });
    //
    // if (sessionStorage.getItem('__lock')) {
    //   sessionStorage.clear();
    // }
    //
    // sessionStorage.setItem('__lock', '1')
  }
};
</script>
