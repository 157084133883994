import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import AppScss from '@/assets/style/App.scss';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
  AppScss,
  icons: {
    iconfont: 'md' //|| 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  theme: {
    options: {
      customProperties: true
    }
  },

  breakpoint: {
    mobileBreakpoint: "sm"
  }
});
