import Axios from "@/plugins/axios"
import { objectToFormData } from "object-to-formdata"

const default_url = process.env.VUE_APP_ENDPOINT

export const getData = (hash) => {
  let token  = JSON.parse(localStorage.getItem(hash))
  // let token  = JSON.parse(sessionStorage.getItem(hash))
  const now = Math.floor(Date.now() / 1000)

  if (token && now > token.expiry) {
    localStorage.setItem(hash, null)
    // sessionStorage.setItem(hash, null)
  }

  token = JSON.parse(localStorage.getItem(hash))
  // token = JSON.parse(sessionStorage.getItem(hash))
  token = token ? token.value : ""

  return Axios.get(`${default_url}data`, { headers: { hash, token } })
}

export const getTheme = (hash) => {
  return Axios.get(`${default_url}theme`, { headers: { hash } })
}

export const sendData = (hash, typology, data, extraHeaders={}) => {
  // const payload = !data ? null : objectToFormData(data)
  return Axios.post(`${default_url}${typology}`, data, { headers: { hash, ...extraHeaders } })
}

export const actionCall = (actionName) => {
  return Axios.get(`${default_url}${actionName}`)
}

export const getFile = (hash) => {
  return Axios.get(`${default_url}document/download`, { headers: { hash } })
}

export const getNewOtp = (hash) => {
  return Axios.get(`${default_url}otp`, { headers: { hash } })
}

export const getNewOtpSign = (hash) => {
  return Axios.get(`${default_url}signature-otp`, { headers: { hash } })
}

export const getCertificateAuthenticationURL = (hash) => {
  return Axios.get(`${default_url}certificate-auth-url`, { headers: { hash } })
}

export const getAutenticacaoURL = (hash) => {
  return Axios.get(`${default_url}autenticacao-auth-url`, { headers: { hash } })
}

export const downloadSingleSignedFile = (hash, documentId) => {
  return Axios.get(`${default_url}single-signed-file`, { headers: { hash, documentId } })
}

export const downloadAllFiles = (hash, token) => {
  return Axios.get(`${default_url}all-signed-files`, { headers: { hash, token } })
}

export const downloadSingleSignedFileIntervener = (hash, documentId) => {
  return Axios.get(`${default_url}single-signed-file-intervener`, { headers: { hash, documentId } })
}

export const downloadAllFilesIntervener = (hash, token) => {
  return Axios.get(`${default_url}all-signed-files-intervener`, { headers: { hash, token } })
}

export const rejectDocument = (hash, data) => {
  const payload = objectToFormData(data)
  return Axios.post(`${default_url}reject`, payload, { headers: { hash } })
}

export const getDocumentImageIdentificationToken = (hash) => {
  return Axios.get(`${default_url}document-image-token`, { headers: { hash } })
}

export const sendDocumentImageResult = (hash, veridasEventCode, validationId) => {
  return Axios.get(`${default_url}document-image-result`, { headers: { hash, veridasEventCode, validationId } })
}

export const sendAutofirmaDocument = async (hash, data) => {
  const payload = objectToFormData(data)
  return Axios.post(`${default_url}upload-externally-signed-document`, payload, { headers: { hash } })
}

export const sendAMACredentials = async (hash, data) => {
  const payload = objectToFormData(data)
  return Axios.post(`${default_url}prepare-ama-signature`, payload, { headers: { hash } })
}

export const sendAMAOtp = async (hash, data) => {
  const payload = objectToFormData(data)
  return Axios.post(`${default_url}confirm-ama-signature`, payload, { headers: { hash } })
}

export const sendConsents = async (hash, consentMap) => {
  const payload = objectToFormData(consentMap)
  return Axios.post(`${default_url}consent`, payload, { headers: { hash } })
}
