<template>
  <component
    v-if="!isIdentified"
    :is="createdComponent"
    id="fr-transacton_rejected-model"
    :viewVariables="{ responseMessage, loading }"
  />
</template>

<script>
import { htmlVariableReplace, createModelComponent, createComponentData } from "@/utils/utils"
import { getModelResources } from "@/utils/modelUtils"

  export default {
    name: "TransactionRejectedWrapper",

    props: {
      dataObject: Object,
    },

    data() {
      return {
        modelObject: null,
        html: null,
        createdComponent: null,
        isIdentified: false,
        responseMessage: null,
        loading: false,
      }
    },

    async created() {
      const modelResources = await getModelResources(this.dataObject.typology, this.dataObject.model)
      this.html = htmlVariableReplace(modelResources.html, modelResources.modelObject.variables, this.dataObject.mapping)
      this.modelObject = modelResources.modelObject

      const componentData = createComponentData(this.modelObject.requestVariables)

      this.createdComponent = createModelComponent(this.html, componentData)

      this.$emit("componentLoaded")
    },

    methods: {

    }

  }
</script>
