<template>
  <v-dialog
    v-model="dialogOpen"
    max-width="800"
    persistent
  >
    <v-row dense>
      <v-col>
        <v-card>
          <v-card-subtitle class="pt-1 pb-0">
            <v-row dense justify="center" class="pb-0">
              <v-col cols="12" class="text-center">
                <v-icon id="graphometric-signature-icon" size="65" color="primary">draw</v-icon>
              </v-col>
              <v-col class="text-center ">
                <h1 id="graphometric-signature-title" class="fr-identification_model-1__title font-style-title">
                  {{ dataObject.mapping.graphometricSignaturePopupTitle }}
                </h1>
                <v-divider/>
                <p id="graphometric-signature-text" class="fr-identification_model-1__introduction-text font-style-introduction">
                  {{ dataObject.mapping.graphometricSignaturePopupIntroduction }}
                </p>
              </v-col>
            </v-row>
          </v-card-subtitle>

          <v-card-text>
            <div class="signature-zone">
              <canvas
                ref="canvasRef"
                v-on:pointerdown="handlePointerDown"
                v-on:pointerup="currentPath+=1"
                v-on:pointermove="handlePointerMove"
              />
            </div>
          </v-card-text>

          <v-card-actions>
            <v-row
              dense
              justify="center"
              justify-md="space-between"
              class="px-1"
            >
              <v-col
                sm="6"
                md="6"
                :class="{'text-center': $vuetify.breakpoint.xsOnly, 'text-left': $vuetify.breakpoint.smAndUp }"
              >
                <v-btn
                  color="primary"
                  text
                  @click="$emit('back')"
                >
                  {{ dataObject.mapping.backToDocument }}
                </v-btn>
              </v-col>

              <v-col
                sm="6"
                md="6"
              >
                <v-row
                  dense
                  justify="center"
                  justify-sm="end"
                  justify-md="end"
                  justify-lg-="end"
                >
                  <v-btn
                    outlined
                    color="primary"
                    class="mr-4"
                    @click="cleanAll"
                  >
                    {{ dataObject.mapping.clear }}
                  </v-btn>

                  <v-btn
                    color="primary"
                    :disabled="paths.length < 1"
                    @click="confirmGraph"
                  >
                    {{ dataObject.mapping.submit }}
                  </v-btn>

                </v-row>
              </v-col>
            </v-row>

          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
  import { getStroke } from "perfect-freehand"
  export default {
    name: "GraphometricSignatureManager",
    props: {
      dataObject: Object,
      userActions: Object

    },

    data() {
      return {
        currentPath: 0,
        dialogOpen: true,
        paths: [],
        options: {
          size: 6,
          smoothing: 0.5,
          thinning: 0.5,
          streamline: 0.5,
          easing: (t) => t,
          start: {
            taper: 0,
            cap: true,
          },
          end: {
            taper: 0,
            cap: true,
          },
        }
      }
    },

    mounted() {
      this.drawPlaceholder()

      const obs = new ResizeObserver(entries => {
        for (let entry of entries) {
          const cr = entry.contentRect;
          this.drawPlaceholder(cr)
        }
      })

      obs.observe(this.$refs.canvasRef)
    },

    methods: {
      cleanAll() {
        this.paths = []
        this.currentPath = 0
      },

      relativeCoords (event) {
        const bounds = event.target.getBoundingClientRect()
        const x = Math.round(event.clientX - bounds.left)
        const y = Math.round(event.clientY - bounds.top)

        return { x, y }
      },

      handlePointerDown(e) {
        e.target.setPointerCapture(e.pointerId)
        const relCoords = this.relativeCoords(e)
        this.paths.push({ id: this.currentPath, points: [[relCoords.x, relCoords.y, e.pressure, Math.trunc(Date.now() / 1000)]] })

      },

      handlePointerMove(e) {
        if (e.buttons !== 1) return
        const relCoords = this.relativeCoords(e)

        this.paths[this.currentPath].points.push([relCoords.x, relCoords.y, e.pressure, Math.trunc(Date.now() / 1000)])
      },


      getSvgPathFromStroke(points, closed = false) {
        const average = (a, b) => (a + b) / 2
        const len = points.length

        if (len < 4) {
          return ``
        }

        let a = points[0]
        let b = points[1]
        const c = points[2]

        let result = `M${a[0].toFixed(2)},${a[1].toFixed(2)} Q${b[0].toFixed(2)},${b[1].toFixed(
          2
        )} ${average(b[0], c[0]).toFixed(2)},${average(b[1], c[1]).toFixed(2)} T`

        for (let i = 2, max = len - 1; i < max; i++) {
          a = points[i]
          b = points[i + 1]
          result += `${average(a[0], b[0]).toFixed(2)},${average(a[1], b[1]).toFixed(2)} `
        }

        if (closed) {
          result += 'Z'
        }

        return result
      },

      confirmGraph() {
        const nPaths = this.paths.length
        const initTimestamp = this.paths[0].points[0][3]
        const endTimestamp = this.paths[nPaths - 1].points[this.paths[nPaths - 1].points.length - 1][3]

        const duration = endTimestamp - initTimestamp

        const payload = {
          signature_graphometric: {
            image: this.$refs.canvasRef.toDataURL(),
            coords: this.paths,
            initTimestamp,
            duration: duration * 1000
          }
        }


        for (const point of payload.signature_graphometric.coords) {
          point.points.map(x => x.splice(3, 1))
        }

        this.$emit("confirmSignatures", payload)
      },

      drawPath(pathData) {
        const canvas = this.$refs.canvasRef
        const ctx = canvas.getContext("2d")
        const rect = canvas.getBoundingClientRect()
        const dpr = /*window.devicePixelRatio ||*/ 1

        canvas.width = rect.width * dpr
        canvas.height = rect.height * dpr

        const p = new Path2D(pathData)
        ctx.fill(p)
      },

      drawPlaceholder(r) {
        const color = getComputedStyle(document.documentElement).getPropertyValue('--v-primary-base').trim()
        const font = document.querySelector(".v-application").style.fontFamily

        const canvas = this.$refs.canvasRef
        if (canvas) {
          const ctx = canvas.getContext("2d")

          const rect = r || canvas.getBoundingClientRect()

          canvas.width = rect.width
          canvas.height = rect.height

          ctx.clearRect(0, 0, canvas.width, canvas.height);

          ctx.fillStyle = color;
          ctx.font = `normal 1rem ${font}`
          ctx.textAlign = 'center'
          ctx.textBaseline = 'stretch'
          ctx.fillText(this.dataObject.mapping.graphometricSignaturePopupPlaceholder, (canvas.width / 2), (canvas.height * 0.7))
          ctx.strokeStyle = color

          const lineWidth = canvas.width * 0.8
          const x = (canvas.width - lineWidth) / 2
          const y = canvas.height * 0.8

          ctx.beginPath()
          ctx.moveTo(x, y)
          ctx.lineTo(x + lineWidth, y)
          ctx.stroke()
        }


      }
    },

    watch: {
      paths: {
        deep: true,
        handler () {
          if (this.paths.length === 0) {
            this.drawPlaceholder()
          } else {
            const finalPath = []
            for (const path of this.paths) {
              const stroke = getStroke(path.points, this.options)
              finalPath.push(this.getSvgPathFromStroke(stroke))

            }

            this.drawPath(finalPath)
          }
        }
      }
    }
    
  }
</script>

<style lang="scss">
  .signature-zone {
    border: 2px dashed var(--v-primary-base);
    height: 45vh;
    background-color: var(--v-primary-base-1);

    canvas {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      touch-action: none;
      padding: 4px;
      z-index: 100;
    }

    &__placeholder {
      position: absolute !important;
      top: 50%;
      left: 50%;
      width: 70%;
      margin-top: 10%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 8px;

      font-size: 16px !important;
      border-bottom: 2px solid var(--v-primary-base);
      opacity: 0.8;
      z-index: 0;

      span {
        color: var(--v-primary-base);
      }
    }
  }

</style>
