<template>
  <v-fade-transition>
    <component
      v-if="!isIdentified"
      :is="createdComponent"
      id="fr-transacton_finished-model"
      :viewVariables="{ documents: dataObject.documents, allowDownload: dataObject.allowDownload }"
      @downloadSingleFile="downloadSingleFile"
      @downloadAllFiles="downloadAllFiles"
    />
  </v-fade-transition>
</template>

<script>
import { htmlVariableReplace, createModelComponent, createComponentData, generateFileDownload } from "@/utils/utils"
import { getModelResources } from "@/utils/modelUtils"
import { downloadSingleSignedFile, downloadAllFiles } from "@/services/generalService"

  export default {
    name: "TransacationFinishedWrapper",

    props: {
      dataObject: Object,
    },

    data() {
      return {
        modelObject: null,
        html: null,
        createdComponent: null,
        isIdentified: false,
        responseMessage: null,
        loading: false,
      }
    },

    async created() {
      const modelResources = await getModelResources(this.dataObject.typology, this.dataObject.model)
      this.html = htmlVariableReplace(modelResources.html, modelResources.modelObject.variables, this.dataObject.mapping)
      this.modelObject = modelResources.modelObject

      const componentData = createComponentData(this.modelObject.requestVariables)

      this.createdComponent = createModelComponent(this.html, componentData)

      this.$emit("componentLoaded")
    },

    methods: {
      downloadSingleFile(eventData) {
        downloadSingleSignedFile(this.dataObject.currentHash, eventData.extra).then(response => {
          generateFileDownload(response.data.extra.fileContent, response.data.extra.fileName, response.data.extra.fileType)
        })
      },

      downloadAllFiles() {
        const token = JSON.parse(localStorage.getItem(this.dataObject.currentHash))
        // const token = JSON.parse(sessionStorage.getItem(this.dataObject.currentHash))

        downloadAllFiles(this.dataObject.currentHash, token.value).then(response => {
          generateFileDownload(response.data.extra.fileContent, response.data.extra.fileName, response.data.extra.fileType)
        })
      }
    }

  }
</script>
