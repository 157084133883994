<template>
  <component
    id="fr-consent_finished-model"
    :is="createdComponent"
    :viewVariables="{ consents: dataObject.consents, consentsMap, isValid, activePanels, toggleTexts, togglePanel,showOrHidePanel }"
    @submit="submit"
  />
</template>

<script>
import { getModelResources } from "@/utils/modelUtils";
import { createComponentData, createModelComponent, htmlVariableReplace } from "@/utils/utils";
import { sendConsents } from "@/services/generalService";

export default {
    name: "ConsentWrapper",

    props: {
      dataObject: Object
    },

    computed: {
      isValid() {
        return Object.entries(this.consentsMap).every(([consentId, value]) => {
          const allow_no_response = this.dataObject.consents.find(consent => consent.id === consentId).allow_no_response
          return parseInt(value) >= 0 || (allow_no_response && parseInt(value) === -1)
        })
      },

      toggleTexts() {
        return [this.dataObject.mapping.moreInfoConsentText, this.dataObject.mapping.lessInfoConsentText]
      }
    },

    data() {
      return {
        createdComponent: null,
        consentsMap: {}, // -1 no contestado, 0 rechazado, 1 aceptado, -2 no contestado
        activePanels: []
      }
    },

    async created() {
      const modelResources = await getModelResources(this.dataObject.typology, this.dataObject.model)
      this.html = htmlVariableReplace(modelResources.html, modelResources.modelObject.variables, this.dataObject.mapping)
      this.modelObject = modelResources.modelObject
      const componentData = createComponentData(this.modelObject.requestVariables)

      this.createdComponent = createModelComponent(this.html, componentData)

      this.consentsMap = Object.fromEntries(
        this.dataObject.consents.map(consent => [consent.id, consent.response?.toString() || -2])
      );

      if (parseInt(this.dataObject.collapsedPurposesByDefault) !== 1) {
        this.activePanels = Array.from({ length: this.dataObject.consents.length }, (_, i) => i)
      }

      this.$emit("componentLoaded")
    },

  methods: {
    async submit() {
      const {data} = await sendConsents(this.dataObject.currentHash, {consents: this.consentsMap})

      if (data.message === "ok") {
        location.reload()
      }

    },

    togglePanel(activePanels) {
      this.activePanels = activePanels
    },
    showOrHidePanel(index) {
      this.activePanels = this.activePanels.includes(index)
        ? this.activePanels.filter(panelIndex => panelIndex !== index)
        : [...this.activePanels, index]
    }
  }

  }
</script>

<style scoped lang="scss">
  #fr-consent_finished-model {
    overflow-y: auto !important;
  }
</style>