<template>
  <v-row
    dense
    :id="action.id"
    class="fr-sign-button fr-sign-button fr-signature-button-pending"
    :class="`sign-button-${actionNumber}`"
    :style="rowStyle"
  >
    <v-btn
      :title="`${mapping.documentPanelSignatureTag} ${actionNumber} / ${nTotalOfActions}`"
      :style="buttonStyle"
      @click="handleAction"
    >
      <span class="fr-signature-button-pending__text">{{ mapping.signatureActionButtonText }}</span>
      <span class="d-none fr-sign-button-text-completed">
        <v-icon class="pb-1">done</v-icon>
        {{ mapping.documentPanelSignActive }}
      </span>
    </v-btn>

    <div ref="actionCompleted-slot"></div>

    <SignTag
      :id="`signaturetag_${action.id}`"
      style="left: calc(-300px * 0.75); height: 100px;"
      :mapping="mapping"
      :signatureNumber="`${actionNumber}/${nTotalOfActions}`"
    />
  </v-row>
</template>

<script>
import SignTag from "@/components/SignatureTag.vue"
import Scaler from "@/utils/Scaler";
import { hexToRgb, pixelsToPoints, pointsToPixels } from "@/utils/utils";

export default {
  name: "DocumentAction",
  components: { SignTag },
  props: {
    mapping: {
      type: Object,
      required: true
    },
    actionNumber: {
      type: Number,
      required: true
    },
    nTotalOfActions: Number,
    nOfAppliedSignatures: Number,
    action: Object,
    pageContainer: HTMLElement,
    documentSize: {
      type: Object,
      validator: (obj) => {
        return Object.keys(obj).every(x => ["width", "height"].includes(x))
      }
    },

    isCompleted: {
      type: Boolean,
      default: () => false
    },

    completedElementSlot: HTMLElement
  },

  data() {
    return {
      actionApplied: false,
      cs: null
    }
  },

  computed: {
    canvasSize() {
      if (!this.cs) {
        const canvas = this.pageContainer.querySelector("canvas")
        return { width: pixelsToPoints(canvas.offsetWidth), height: pixelsToPoints(canvas.offsetHeight) }
      }

      return this.cs

    },

    actionPosition() {
      return Scaler.getPositionBasedOnDocumentSize(this.action, this.canvasSize)
    },

    actionSize() {
      return Scaler.getSizeBasedOnDocumentSize(this.documentSize, this.action, this.canvasSize)
    },

    rowStyle() {
      return `bottom: ${this.actionPosition.y > this.height ? this.height : this.actionPosition.y}pt;`
    },

    buttonStyle() {
      const opacity = this.actionApplied || this.isCompleted ? 0 : 1
      let style = `background-color: rgba(${hexToRgb(this.$vuetify.theme.themes.light.primary)}, ${opacity});`

      const buttonSize = `
        width: ${pointsToPixels(this.actionSize.width)}px;
        max-width: ${pointsToPixels(this.actionSize.width)}px;
        height: ${pointsToPixels(this.actionSize.height)}px !important;
      `

      const buttonPosition = `
        left: ${this.actionPosition.x > this.width ? this.width : this.actionPosition.x}pt;
      `

      if (this.actionApplied || this.isCompleted) {
        const actionAppliedStyle = `
          border-top: 2px solid var(--v-primary-base);
          border-bottom: 2px solid var(--v-primary-base);
          border-radius: 0;
        `

        if (!this.action.type.includes("graphometric")) {
          style += actionAppliedStyle
        }
      }

      style += buttonPosition
      style += buttonSize

      return style
    }
  },

  mounted() {
    if (this.isCompleted && !this.action.type.includes("graphometric")) {
      this.setButtonAsSigned()
    }

    if (this.isCompleted && this.action.type.includes("graphometric")) {
      this.setButtonAsGraphometricSigned()
    }
  },

  methods: {
    updateCanvasSize() {
      setTimeout(() => {
        const canvas = this.pageContainer.querySelector("canvas")
        this.pageContainer.querySelector("canvas")
        this.cs = { width: pixelsToPoints(canvas.offsetWidth), height: pixelsToPoints(canvas.offsetHeight) }
      }, 200)

    },

    handleAction() {
      if (this.action.type.includes("signature") && !this.pageContainer.querySelector(`#${this.action.id}`).classList.contains("fr-signature-completed")) {
        this.actionApplied = !this.action.type.includes("graphometric")

        this.$eventBus.emit("document-action:completed", {
          type: this.action.type,
          id: this.action.id
        })
      }
    },

    setButtonAsSigned() {
      this.$el.classList.remove("fr-signature-button-pending")
      this.$el.classList.add("fr-signature-completed")
      this.$el.querySelector(".fr-sign-button-text-completed").classList.remove("d-none")

      this.$el.querySelector(".fr-signature-button-pending__text").classList.add("d-none")
      this.$el.querySelector(".fr-sign-button-text-completed").classList.remove("d-none")
      this.$el.querySelector(`#signaturetag_${this.action.id}`).classList.add("d-none")

    },

    setButtonAsGraphometricSigned() {
      this.$el.classList.remove("fr-signature-button-pending")
      this.$el.classList.add("fr-signature-completed")

      this.$el.querySelector(".fr-signature-button-pending__text").classList.add("d-none")
      this.$el.querySelector(`#signaturetag_${this.action.id}`).classList.add("d-none")

      const button = this.$el.querySelector("button")
      this.completedElementSlot.setAttribute("style", button.getAttribute("style"))
      this.completedElementSlot.setAttribute("style", this.completedElementSlot.getAttribute("style") + "position:absolute; object-fit: contain;")

      this.$refs["actionCompleted-slot"].replaceWith(this.completedElementSlot)
    }
  },

  watch: {
    documentSize: {
      handler() {
        this.updateCanvasSize()
      },

      deep: true
    }
  }

}
</script>