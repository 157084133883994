export default {
  DOCUMENT_TYPOLOGY: "document",
  FORM_IDENTIFICATION_TYPOLOGY: "form-identification",
  IN_USE_TYPOLOGY: "in_use",
  INTERVENER_FINISHED_TYPOLOGY: "intervener_finished",
  LAYOUT_TYPOLOGY: "layout",
  OTP_IDENTIFICATION_TYPOLOGY: "otp-identification",
  OTP_SIGN_TYPOLOGY: "otp-sign",
  TRANSACTION_CANCELED_TYPOLOGY: "transaction_canceled",
  TRANSACTION_EXPIRED_TYPOLOGY: "transaction_expired",
  TRANSACTION_FINISHED_TYPOLOGY: "transaction_finished",
  WAITING_OTHER_INTERVENERS_TYPOLOGY: "waiting_other_interveners",
  CERTIFICATE_IDENTIFICATION: "certificate-identification",
  OTP_LIMIT_REACHED: "otp_limit_reached",
  SIGNATURE_CONDITIONS_ACCEPTANCE: "signature_conditions_acceptance",
  PREPEND_PAGE: "prependPage",
  TRANSACTION_REJECT_TYPOLOGY: "transaction_rejected",
  DOCUMENT_IMAGE_IDENTIFICATION_TYPOLOGY: "identification-document-image",
  RELIABLE_IDENTIFICATION: "reliable-identification",
  ACCEPT_OR_REJECT_TRANSACTION: "accept_or_reject_transaction",
  CONSENT_TYPOLOGY: "consent",


}
