// eslint-disable
// import DOCUMENT_CONSTANTS from "@/constants/documentConstants"
import {pixelsToPoints} from "@/utils/utils"

class Scaler {
  // eslint-disable-next-line
  static getPositionBasedOnDocumentSize(action, canvasSize) {
    return {
      x: canvasSize.width * action.basePosition.x / action.pageSize.width,
      y: canvasSize.height * action.basePosition.y / action.pageSize.height
    }
  }

  static getSizeBasedOnDocumentSize(documentSize, action, canvasSize) {  
    const size =  {
      width: canvasSize.width * action.width / documentSize.width,
      height: canvasSize.height * action.height / documentSize.height
    }

    if (action.pageSize.height != documentSize.height) {

      if (document.querySelector(`#pdfcontainer-${action.page} .textLayer`)) {
        const canvasWidth = pixelsToPoints(document.querySelector(`#pdfcontainer-${action.page} .textLayer`).offsetWidth)
        const canvasHeight = pixelsToPoints(document.querySelector(`#pdfcontainer-${action.page} .textLayer`).offsetHeight)

        size.width = canvasWidth * action.width / action.pageSize.width
        size.height = canvasHeight * action.height / action.pageSize.height
      }
    }

    return size
  }
}

export default Scaler