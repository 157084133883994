<template>
  <div
    class="fr-signature-status-bar pb-3 "
    :style="`width:${width.toString()}px !important;`"
  >
    <v-row dense justify="center" class="px-4 pb-1 pt-3">
      <v-col cols="12" class="text-center">
        <span v-if="actionStatus.completed === actionStatus.total">
          <v-icon color="success" id="signature-status-bar-bottom_all_signatures_completed_icon">check_circle</v-icon>
          <span class="secondary-text--text" id="signature-status-bar-bottom_all_signatures_completed">{{ mapping.allSignaturesCompleted }}</span>
        </span>

        <span v-else>
          <v-icon color="error">error</v-icon>
          <span
            class="pl-2 secondary-text--text"
            v-html="mapping.pendingSignaturesWarning.replace('%pending_signatures%', actionStatus.total - actionStatus.completed)"
            id="signature-status-bar-bottom_all_signatures_completed"
          ></span>
        </span>
      </v-col>

      <v-col cols="12" class="text-center pt-3">
        <v-btn
          v-if="actionStatus.completed !== actionStatus.total"
          id="signature-status-bar-next_signature"
          @click="$emit('signCursor:prev')"
          class="primary--text"
          text
        >
          <span class="text-decoration-underline">{{ mapping.documentPanelNextInteraction }}</span>
          <v-icon small class="pl-2 mb-1" style="transform: rotate(90deg) !important">turn_left</v-icon>
        </v-btn>

        <v-btn
          v-else
          id="signature-status-bar-bottom_submit_signatures"
          :loading="stateLoading"
          class="primary"
          @click="handleClickSubmit"
        >
          <v-icon small class="pr-2">done</v-icon>
          {{ mapping.submit }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: "SignatureStatusBottomBar",
    props: {
      width: Number,
      mapping: Object,
      actionStatus: Object,
      isLoading: Boolean
    },

    data() {
      return {
        loading: false
      }
    },

    computed: {
      stateLoading() {
        return this.loading
      }
    },

    methods: {
      handleClickSubmit() {
        this.loading = true
        this.$eventBus.emit("syncLoadings", true)

        this.$emit('signProcessCompleted')
        setTimeout(() => {
          this.loading = false
          this.$eventBus.emit("syncLoadings", false)
        }, 3500)
      }
    },

    watch: {
      isLoading(newVal) {
        this.loading = newVal
      }
    }
  }
</script>

<style lang="scss" scoped>
  .fr-document-action_bar__zoom-button {
    height: 40px !important;
    min-width: 40px !important;
    width: 40px !important;
    border-color: var(--v-primary-base) !important;
  }
  .fr-document-action_bar__zoom-button:first-of-type {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .fr-document-action_bar__zoom-button:nth-of-type(2) {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
</style>