<template>
  <v-fade-transition>
    <component
      :is="createdComponent"
      @submit="handleSubmit"
      @clickCheck="handleClickCheck"
      id="fr-signature-contitions-model"
      :viewVariables="{ ppr }"
      ref="signatureconditionscomponent"
    />
  </v-fade-transition>
</template>

<script>
import { htmlVariableReplace, createModelComponent, createComponentData } from "@/utils/utils"
import { getModelResources } from "@/utils/modelUtils"
import { sendData } from "@/services/generalService"
import typologies from "@/constants/typologies"

  export default {
    name: "SignatureConditionsWrapper",

    props: {
      dataObject: Object,
    },


    data() {
      return {
        modelObject: null,
        html: null,
        createdComponent: null,
        loading: false,
        responseMessage: null,
        ppr: 0
      }
    },

    async created() {
      const modelResources = await getModelResources(this.dataObject.typology, this.dataObject.model)
      this.html = htmlVariableReplace(modelResources.html, modelResources.modelObject.variables, this.dataObject.mapping)
      this.modelObject = modelResources.modelObject

      const componentData = createComponentData(this.modelObject.requestVariables)

      this.createdComponent = createModelComponent(this.html, componentData)

      this.$emit("componentLoaded")

    },

    methods: {
      async handleSubmit(x) {
        const ppr = x.componentData.returnData.ppr
        if(ppr){
          this.loading = true
          const action = ppr ? "accepted" : "rejected"

          const headers = {
            ppr: action,
            ppt: this.dataObject.typology
          }

          const response = await sendData(
            this.dataObject.currentHash,
            typologies.PREPEND_PAGE,
            {},
            headers
          )

          if (response.data.error === 0) {
            this.$emit("stepCompleted")
          }
        }
      },

      handleClickCheck(x) {
        if( x.componentData.returnData.ppr == null){
          this.$set(x.componentData.returnData,'ppr',0)
        }
        x.componentData.returnData.ppr = x.componentData.returnData.ppr === 0 ? 1 :0
      }
    }

  }
</script>
