<template>
  <v-row dense class="error-wrapper_container" justify="center">
      <v-row dense class="ma-0 my-auto" justify="center">
        <v-col cols="4" align-self="center">
          <v-card class="fr-identification_model-1_card" flat>
            <v-card-title>
              <v-row dense>
                <v-col cols="12" class="py-4">
                  <v-row dense justify="center">
                    <span class="text-center">Ha ocurrido un error</span>
                  </v-row>
                </v-col>
                <v-col class="py-4">
                  <v-row dense justify="center">
                    <v-icon x-large color="error">report</v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-row dense justify="center">
                <v-col class="text-center">
                  <span>
                    Por favor pongáse en contacto con la entidad que le ha facilitado el enlace.
                  </span>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
  </v-row>
</template>

<script>
  export default {
    name: "ErrorWrapper"
  }
</script>

<style lang="scss" scoped>
  .error-wrapper_container {
    height: 100%;
    padding:48px;
  }
</style>
