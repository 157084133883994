<template>
  <v-overlay :value="loading" v-if="loading">
    <v-progress-circular
      indeterminate
      size="64"
      color="primary"
    ></v-progress-circular>
  </v-overlay>

  <div id="landing-wrapper" v-else>
    <v-row dense justify="center" class="fill-height pa-0 ma-0" style="max-width: 100vw !important">
      <ErrorWrapper v-if="error"/>

      <component :is="layoutComponent" id="fr-layout-model" v-if="!error && layoutComponent">
        <template v-slot:language-selector>
          <LanguageSelector :availableLanguages="availableLanguages"/>
        </template>

        <template v-slot:in_use-model v-if="initData.typology === typologies.IN_USE_TYPOLOGY">
          <InUseWrapper :dataObject="dataObject" />
        </template>

        <template v-slot:consent-model v-else-if="initData.typology === typologies.CONSENT_TYPOLOGY">
          <ConsentWrapper
            :dataObject="dataObject"
          />
        </template>

        <template v-slot:intervener_finished-model v-else-if="initData.typology === typologies.WAITING_OTHER_INTERVENERS_TYPOLOGY">
          <WaitingOtherIntervenersWrapper :dataObject="dataObject" />
        </template>

        <template v-slot:waiting_others_interveners-model v-else-if="initData.typology === typologies.INTERVENER_FINISHED_TYPOLOGY">
          <IntervenerFinishedWrapper :dataObject="dataObject" />
        </template>

        <template v-slot:transaction_finished-model v-else-if="initData.typology === typologies.TRANSACTION_FINISHED_TYPOLOGY">
          <TransactionFinishedWrapper :dataObject="dataObject" />
        </template>

        <template v-slot:transaction_expired-model v-else-if="initData.typology === typologies.TRANSACTION_EXPIRED_TYPOLOGY">
          <TransactionExpiredWrapper :dataObject="dataObject" />
        </template>

        <template v-slot:transaction_canceled-model v-else-if="initData.typology === typologies.TRANSACTION_CANCELED_TYPOLOGY">
          <TransactionCanceledWrapper :dataObject="dataObject" />
        </template>

        <template v-slot:identification-model v-else-if="initData.typology.indexOf('identification') !== -1">
          <IdentificationWrapper
            :dataObject="dataObject"
            @identificationCompleted="handleIdentification"
            @reload="fetchData"
            @componentLoaded="componentLoaded"
          />
        </template>

        <template v-slot:sign-model v-else-if="initData.typology === typologies.SIGNATURE_CONDITIONS_ACCEPTANCE">
          <SignatureConditionsWrapper
            :dataObject="dataObject"
            @stepCompleted="handleStepCompleted"
          />
        </template>

        <template v-slot:sign-model v-else-if="initData.typology === typologies.ACCEPT_OR_REJECT_TRANSACTION">
          <AcceptOrRejectTransaction
            :dataObject="dataObject"
            @stepCompleted="handleStepCompleted"
          />
        </template>

        <template v-slot:sign-model v-else-if="initData.typology === typologies.TRANSACTION_REJECT_TYPOLOGY">
          <TransactionRejectedWrapper
            :dataObject="dataObject"
            @stepCompleted="handleStepCompleted"
          />
        </template>

        <template v-slot:sign-model v-else>
          <SignWrapper :dataObject="dataObject" />
        </template>

      </component>
    </v-row>
  </div>
</template>

<script>
import ConsentWrapper from "@/components/wrappers/ConsentWrapper"
import IdentificationWrapper from "@/components/wrappers/IdentificationWrapper"
import SignWrapper from "@/components/wrappers/SignWrapper"
import InUseWrapper from "@/components/wrappers/InUseWrapper"
import WaitingOtherIntervenersWrapper from "@/components/wrappers/WaitingOtherIntervenersWrapper"
import IntervenerFinishedWrapper from "@/components/wrappers/IntervenerFinishedWrapper"
import TransactionFinishedWrapper from "@/components/wrappers/TransactionFinishedWrapper"
import TransactionCanceledWrapper from "@/components/wrappers/TransactionCanceledWrapper"
import TransactionExpiredWrapper from "@/components/wrappers/TransactionExpiredWrapper"
import LanguageSelector from "@/components/LanguageSelector"
import ErrorWrapper from "@/components/wrappers/ErrorWrapper"
import typologies from "@/constants/typologies"
import { getModelResources } from "@/utils/modelUtils"
import {
  changeFavicon,
  changeFont,
  changePageTitle,
  createCssOpacityColors,
  createModelComponent,
  htmlVariableReplace,
  loadCssResource,
  resetScroll
} from "@/utils/utils"
import { actionCall, getData, getTheme } from "@/services/generalService"
import SignatureConditionsWrapper from '@/components/wrappers/SignatureConditionsWrapper'
import TransactionRejectedWrapper from '@/components/wrappers/TransactionRejectedWrapper'
import AcceptOrRejectTransaction from '@/components/wrappers/AcceptOrRejectTransaction'

export default {
  name: 'LandingWrapper',
  components: {
    ConsentWrapper,
    TransactionRejectedWrapper,
    SignatureConditionsWrapper,
    IdentificationWrapper,
    SignWrapper,
    InUseWrapper,
    WaitingOtherIntervenersWrapper,
    IntervenerFinishedWrapper,
    TransactionFinishedWrapper,
    TransactionCanceledWrapper,
    TransactionExpiredWrapper,
    ErrorWrapper,
    LanguageSelector,
    AcceptOrRejectTransaction
  },

  props: {
    currentHash: String
  },

  data () {
    return {
      typologies,
      loading: true,
      initData: null,
      layoutComponent: null,
      error: false,
      languages: null
    }
  },

  computed: {
    dataObject() {
      return { ...this.initData, ...{ currentHash: this.currentHash } }
    },
    availableLanguages() {
      return this.languages
    }
  },

  created () {
    loadCssResource(`${process.env.VUE_APP_RESOURCES_ENDPOINT}models/general.css`)
    // this.$router.replace({ ...this.$router.currentRoute, params: { hash: null } })

    this.$eventBus.on("loading", value => {
      this.loading = value
    })

    this.$eventBus.on("signCompleted", () => {
      this.fetchData()
    })

    this.$eventBus.on("signRejected", () => {
      this.fetchData()
    })

    this.$eventBus.on("stepCompleted", () => {
      this.fetchData()
    })

    this.$eventBus.on("error500", () => {
      this.error = true
    })

    getTheme(this.currentHash)
      .then(async response => {
        this.$vuetify.theme.themes.light = response.data.extra.theme
        changeFavicon(response.data.extra.layout.favicon)

        if (response.data.extra.layout.font !== "default") {
          changeFont(response.data.extra.layout.font)
        }

        createCssOpacityColors(this.$vuetify.theme.themes.light)

        if (response.data.error === 0) {
          this.languages = response.data.extra.languages

          await this.fetchData()
          const modelResources = await getModelResources("layout", response.data.extra.layout.model)
          const htmlLayout = htmlVariableReplace(modelResources.html, modelResources.modelObject.variables, { ...this.dataObject.mapping, ...response.data.extra.mapping })

          this.layoutComponent = createModelComponent(htmlLayout, { variables: { ...this.dataObject.mapping, ...response.data.extra.mapping } }, () => {}, { actionCall })

        } else {
          this.loading = false
          this.$router.push({ name: "InvalidRoute" })
        }
      })

      .catch((error) => {
        console.error("ERROR THEME", error)
        this.error = true
      })

    window.addEventListener("message", (event) => {
      if (event.origin.includes('xpressid')) {
        this.$eventBus.emit("iframeMessage", event)
      }
    })
  },

  methods: {
    async createComponent(model, mapping) {
      const modelResources = await getModelResources("layout", model)
      const htmlLayout = htmlVariableReplace(modelResources.html, modelResources.modelObject.variables, mapping)

      this.layoutComponent = createModelComponent(htmlLayout, { variables: mapping }, () => {}, { actionCall })
    },

    async fetchData(loadingTime=100) {
      this.handleLoadingChange(true)

      await getData(this.currentHash).then((response) => {
        resetScroll()
        this.initData = response.data

        if (this.initData.token != undefined) {
          this.setToken(this.initData.token, this.initData.tokenExpiration)
        }

        changePageTitle(this.initData.mapping.pageTitle)

        this.handleLoadingChange(false, loadingTime)
      })
      .catch(() => {
        this.error = true
      })

    },

    handleIdentification() {
      this.handleLoadingChange(true)
      this.fetchData()
    },
    handleStepCompleted() {
      this.handleLoadingChange(true)
      this.fetchData()
    },

    componentLoaded() {
      this.handleLoadingChange(false, 400)
    },

    setToken(token, expirationTimestamp) {
      localStorage.setItem(this.$route.params.hash, JSON.stringify({ value: token, expiry: expirationTimestamp }))
      // sessionStorage.setItem(this.$route.params.hash, JSON.stringify({ value: token, expiry: expirationTimestamp }))
    },

    handleLoadingChange(value, time=1000) {
      if (value) {
        this.loading = true
      } else {
        setTimeout(() => {
          this.loading = false
        }, time)
      }
    }
  }

}
</script>
<style>
  #landing-wrapper{
    height: 100%;
    width: 100% !important;
  }
  #fr-layout-model{
    height: 100% !important;
    display: flex;
    flex-direction: column;
  }
  #layout-body{
    /* flex: auto; */
  }
</style>
